.container {
	height: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: height 0.5s;
	position: relative;

	&.autobidOpen {
		height: 50%;
	}
}

.value {
	width: 100%;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.8rem 4vw;
	border-radius: 12px;
	margin: 1% 0;
	background: var(--secondary-color-light);
	transition: width 0.5s;

	&.smallInput {
		width: 60%;
	}
}

.increment {
	position: absolute;
	height: 4rem;
	width: 4rem;
	border-radius: 100%;
	background: var(--primary-color-light);
	border: solid 1px var(--secondary-color-dark);

	&.left {
		left: -5rem;
		transition: left 0.5s;

		&.autobidOpen {
			left: 0;
		}
	}

	&.right {
		right: -5rem;
		transition: right 0.5s;

		&.autobidOpen {
			right: 0;
		}
	}
}

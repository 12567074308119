.description {
	height: 100%;
	width: 55%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	opacity: 0.9;
	font-size: 1.4rem;
	@media (max-width: 1600px) {
		font-size: 1.2rem;
	}
}

.upper {
	text-transform: uppercase;
}

.title {
	margin-bottom: 1rem;
	padding: 1rem;
	width: 100%;
	font-size: 1.5rem;
	@media (max-width: 1600px) {
		font-size: 1.3rem;
	}
}

.body {
	font-weight: 400;
	overflow-y: auto;
	padding: 1rem;
}

@import 'styles/variables';

.bidsfeed {
	height: 100%;
	border-radius: 12px;
	width: 25%;
	overflow: hidden;
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
	border: solid 1px var(--secondary-color-dark);
	background-color: var(--primary-color-light);

	&.layout_1 {
		width: 38%;
	}

	// &.layout_2 {
	//     opacity: 0;
	//     display: none;
	// }

	.bidsfeed__header {
		height: 8%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 500;
		letter-spacing: 0.016vw;
		color: var(--primary-color-light);
		background-color: var(--primary-color-dark);
		text-transform: uppercase;

		.bidsfeed__header__subtitle {
			width: 33%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}

	.bidsfeed__footer {
		width: 100%;
		height: 10%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 1rem;
		font-weight: 400;
		border-top: solid 1px var(--secondary-color-dark);
		overflow: hidden;

		.bidsfeed__footer__user {
			width: 40%;
			text-align: left;
			display: flex;
			align-items: center;

			.fa-user {
				margin-right: 0.5rem;
			}
		}

		.bidsfeed__footer__paddle {
			width: 40%;
			text-align: right;
		}
	}

	.bidsfeed__feed {
		height: 82%;
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 8px;
		text-transform: capitalize;

		&.animate-slide {
			animation: slide-down 0.2s linear;
			@keyframes slide-down {
				50% {
					padding-top: 1.6rem;
				}
				100% {
					padding-top: 3.2rem;
				}
			}
		}

		.container {
			display: flex;
			width: 100%;
			height: 3.2rem;
			justify-content: flex-start;
			position: relative;
			overflow: hidden;

			&.streambid {
				transform: scale(1.05);
			}

			.feeditem {
				&:not(.self) {
					&.animate-entry {
						animation: pop-in-left 0.2s linear;
					}
					left: 1rem;
					border-radius: 12px 12px 12px 0;
				}

				&.self {
					&.animate-entry {
						animation: pop-in-right 0.2s linear;
					}
					right: 1rem;
					border-radius: 12px 12px 0 12px;
					background-color: var(--accent-offset-2);
				}

				position: absolute;
				top: 1rem;
				min-height: 2.2rem;
				display: flex;
				align-items: center;
				width: fit-content;
				padding-left: 0.5rem;
				font-weight: 400;
				letter-spacing: 0.0093vw;
				border: solid 1px var(--secondary-color-dark);

				.feeditem__sourceicon {
					height: 15px;
					width: 15px;
					margin-left: 16px;
				}

				.feeditem__description {
					margin-left: 12px;
					margin-right: 16px;
				}

				& .notification {
					display: none;
				}

				&.deleted {
					opacity: 0.6;

					.feeditem__description .value {
						text-decoration: line-through;
					}
				}

				@keyframes pop-in-left {
					0% {
						transform: scale(0.1);
						left: -50%;
						top: 100%;
					}
					50% {
						transform: scale(0.5);
						left: -20%;
						top: 50%;
					}
					100% {
						transform: scale(1);
						left: 1rem;
						top: 1rem;
					}
				}

				@keyframes pop-in-right {
					0% {
						transform: scale(0.1);
						right: -50%;
						top: 100%;
					}
					50% {
						transform: scale(0.5);
						right: -20%;
						top: 50%;
					}
					100% {
						transform: scale(1);
						right: 1rem;
						top: 1rem;
					}
				}
			}

			&.message {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 4.2vh;
				min-height: 40px;
				margin-top: 1.8vh;
				padding: 0 1vw;

				.message__spacer {
					flex-grow: 2;
					height: 1px;
					background-color: var(--secondary-color-dark);
				}

				.message__text {
					height: 3.21vh;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 400;
					flex-grow: 1;
				}
			}
		}

		.bidsfeed__feed-no-bids {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 5vh;
			font-weight: 400;

			.icon {
				font-size: 5vh;
				margin-bottom: 1vh;
				color: darkgray;
			}
		}
	}
}

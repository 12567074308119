.container {
	width: 100%;
	height: 20%;
}

.button {
	width: 100%;
	height: 90%;
	color: var(--primary-color-light);
	border: none;
	border-radius: 40px;
	font-size: 20px;
	font-weight: 600;
	margin: 1% 0;
	background: var(--primary-color-dark);
	border: solid 1px var(--secondary-color-dark);
	text-transform: uppercase;
}

.confirming {
	position: relative;
	height: 90%;
	width: 100%;
	border: none;
	margin: 1% 0;
	border-radius: 40px;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;

	.title {
		font-size: 1.2rem;
		z-index: 2;
		font-weight: 600;
	}

	.slider {
		position: absolute;
		left: 0;
		height: 100%;
		width: 20%;
		border: none;
		border-radius: 32px;
		background: var(--primary-color-dark-offset);
		animation: confirmingslider 5s infinite;

		@keyframes confirmingslider {
			0% {
				width: 20%;
			}

			50% {
				width: 100%;
			}

			0% {
				width: 20%;
			}
		}
	}
}

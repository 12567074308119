.rootadmin__sidebar {
    height: 100vh;
    // max-width: 5%;
    // background-color: #20273D;
    // color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 1vw;

    &__title-icon {
        margin-top: 2.5rem;
        font-size: 1.5rem;
    }

    &__buttons {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__1, &__2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__button {
                height: 2.5rem;
                width: 2.5rem;
                margin-bottom: 2rem;
                font-size: 1rem;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                // color: white;
                // background-color: #20273D;
                border: none;
                padding: 0.8rem;
    
                &-icon {
                    // margin-right: 1rem;
                    height: 1.5rem;
                    width: 1.5rem;
                    font-size: 1.5rem;
                }
            }
        }

        .sidebar__addauctionhouse {
            background-color: #3D6EFF;
        }
        
        &__2 {
            border-top: 1px solid;
            padding-top: 2rem;
        }
    }
} 
@import 'styles/variables';

.addauction__breadcrumbs {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin: 1rem 2rem;

	&__spacer {
		border-left: solid #d7dceb 1px;
		height: 3rem;
	}

	&__item {
		font-size: 1.1rem;
		font-weight: bold;
		padding: 1rem;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.8;
		background: white;

		& span {
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: capitalize;
		}

		&-icon-container {
			height: 2rem;
			width: 2rem;
			margin-right: 1rem;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.disabled {
			opacity: 0.4;
			cursor: default;
		}

		&.completed &-icon-container {
			background-color: #345fdb;
		}

		&.completed &-icon {
			color: #ffffff;
		}

		&.selected {
			transform: scale(1.03);
			color: #345fdb;
			opacity: 1;
		}
	}
}

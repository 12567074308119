.container {
	height: 100%;
	width: 100%;
	background: var(--primary-color-light);
}

.row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1% 5%;
}

.top {
	height: 33%;
}

.middle {
	height: 29%;
}

.bottom {
	height: 30%;
	justify-content: center;
}

.container {
	width: 30%;
	height: 3.4rem;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.action {
	height: 90%;
	width: 95%;
	border: none;
	border-radius: 32px;
	background: var(--primary-color-light);
	color: var(--primary-color-dark);
	border: solid 1px var(--primary-color-dark);
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.1rem;
	text-transform: uppercase;

	.icon {
		margin-right: 1rem;
	}
}

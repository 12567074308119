@import 'styles/variables';

.managementpanel {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		// font-size: $large-24-font-size;
		font-weight: 700;
		width: 100%;
		letter-spacing: 0;
		// margin: 2.5% 0;
		height: 7%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 1rem;
		text-transform: capitalize;

		.toggles {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__container {
		background-color: white;
		height: 93%;
		border-radius: 12px;
		padding: 2%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}
}

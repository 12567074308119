.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.input {
	text-align: center;
	border: none;
	font-weight: 700;
	padding: 0.7rem 0.8rem;
	border-radius: 8px;
	text-transform: capitalize;
	font-size: 1rem;
}

.label {
	text-align: center;
}

@import 'styles/variables';
@import 'styles/clerkconsole/timeline/toggle';

.timeline {
    width: 100%;
    height: 100%;
    padding: 1% 1% 2% 1%;
    border-radius: 12px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
    position: relative;
    display: block;

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            margin-left: 1rem;
        }
    }
    
    &__controls {
        width: 100%;
        height: 18%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 12px;
        // padding: 0 2%;
        margin-bottom: 1%;

        &__label {
            font-weight: 400;
        }

        &__previous {
            &__button {
                font-family: inherit; /* 1 */
                line-height: 1.15; /* 1 */
                margin: 0; /* 2 */
                border: none;
                background-color: inherit;
                font-weight: 700;
            }
        }

        &__start {
            &__button {
                font-family: inherit; /* 1 */
                line-height: 1.15; /* 1 */
                margin: 0; /* 2 */
                border: none;
                background-color: inherit;
                font-weight: 700;
            }
        }

        &__search {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;

            .icon {
                position: absolute;
                left: 1rem;
                opacity: 0.7;
            }

            &__input {
                height: 100%;
                width: 100%;
                border-radius: 8px;
                border: none;
                padding-left: 2.2rem;
                text-align: left;
                font-weight: 400;
            }
        }
    }

    .timeline-list-wrapper {
        height: 80%;
    }

    .timeline__row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
}


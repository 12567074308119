.container {
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: 999;
}

.button {
	margin: 0 0.5rem;
}

.active {
	border-radius: 4px;
	border: solid 3px var(--admin-accent-1);
}

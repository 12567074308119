.broadcast3 {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background: var(--primary-color-light);

	.video-panel {
		height: 90%;
		width: 49%;
		border-radius: 8px;
		border: solid 1px var(--secondary-color-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		background: var(--background-color);
	}

	.video-container {
		width: 80%;
		height: 45%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.videofeed {
			background: cornflowerblue;
			height: 100%;
		}
	}

	.loading-panel {
		height: 90%;
		width: 49%;
		border: solid 1px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.right-panel {
		height: 90%;
		width: 49%;

		.webrtc-connection {
			position: absolute;
			top: 6%;
			right: 2%;
			padding: 0.3rem 0.5rem;
			// width: 12rem;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.icon {
				height: 1rem;
				width: 1rem;
				margin-right: 0.5rem;
				border-radius: 100%;
			}

			.webrtc-connected {
				background: var(--admin-accent-1);
			}

			.webrtc-notconnected {
				background: var(--warning-color);
			}
		}

		.switch-panel {
			position: absolute;
			top: 6%;
			right: 2%;
			padding: 0.3rem 0.5rem;
			width: 12rem;
		}
	}
}

.timeline {
	height: 100%;
	width: 100%;
	background: white;
	position: relative;
	overflow: hidden;
	--transitiontime: 600ms;
	--transitiontype: ease-out;
	border: 1px solid var(--secondary-color-dark);
	border-radius: 12px;
}

.home {
	position: absolute;
	padding: 0.5rem 1rem;
	left: 1rem;
	top: 1rem;
	z-index: 2;
	font-size: 1rem;
	border: none;
	border-radius: 100%;
	box-shadow: 0px 2px 8px #00000029;
	height: 5vh;
	width: 5vh;
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: var(--primary-color-dark);
		color: var(--primary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.leftnav {
	position: absolute;
	padding: 0.5rem 1rem;
	left: 1rem;
	top: 45%;
	z-index: 2;
	font-size: 1rem;
	border: none;
	border-radius: 100%;
	box-shadow: 0px 2px 8px #00000029;
	height: 5vh;
	width: 5vh;
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover:not([disabled]) {
		background: var(--primary-color-dark);
		color: var(--primary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.rightnav {
	position: absolute;
	padding: 0.5rem 1rem;
	right: 1rem;
	top: 45%;
	z-index: 2;
	font-size: 1rem;
	border: none;
	border-radius: 100%;
	box-shadow: 0px 2px 8px #00000029;
	height: 5vh;
	width: 5vh;
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover:not([disabled]) {
		background: var(--primary-color-dark);
		color: var(--primary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.track {
	position: absolute;
	padding-bottom: 1rem;
	height: 100%;
	width: 100%;
	display: flex;
	left: 0;
}

.layout1 {
	.transitionOne {
		width: 200%;
		animation: slide-one-from-right-1 var(--transitiontime)
			var(--transitiontype);
		@keyframes slide-one-from-right-1 {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-7%);
			}
		}
	}
}

.layout2 {
	.transitionOne {
		width: 200%;
		animation: slide-one-from-right-2 var(--transitiontime)
			var(--transitiontype);
		@keyframes slide-one-from-right-2 {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-12.5%);
			}
		}
	}
}

.layout3 {
	.transitionOne {
		width: 200%;
		animation: slide-one-from-right-3 var(--transitiontime)
			var(--transitiontype);
		@keyframes slide-one-from-right-3 {
			0% {
				transform: translateX(0);
			}
			100% {
				transform: translateX(-25%);
			}
		}
	}
}

.transitionNext {
	width: 200%;
	animation: slide-from-right var(--transitiontime) var(--transitiontype);
	@keyframes slide-from-right {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-50%);
		}
	}
}

.transitionPrev {
	width: 200%;
	animation: slide-from-left var(--transitiontime) var(--transitiontype);
	@keyframes slide-from-left {
		0% {
			transform: translateX(-50%);
		}
		100% {
			transform: translateX(0);
		}
	}
}

.frame {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 1px;
}

.framelayout1 {
	grid-template-columns: repeat(7, minmax(0, 1fr));
}

.framelayout2 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

.framelayout3 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.item {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.shadow {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 100;
	background: black;
	opacity: 0.5;
}

.modal {
	z-index: 101;
	display: inline;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 64.44%;
	width: 60%;
}

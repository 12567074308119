@import 'styles/variables';

.salecontrols {
	height: 20%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;

	button {
		width: 45%;
		font-weight: 700;
		border-radius: 24px;
		padding: 2% 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;

		.icon {
			margin-right: 0.8rem;
			font-size: 1rem;
		}

		&:active {
			transform: scale(1.05);
		}
	}

	&__passitem {
		display: flex;
		align-items: center;
		justify-content: center;

		&:focus {
			border-radius: 24px;
			border: 3px solid #292929;
			outline: none;
		}
	}

	&__sellitem {
		font-weight: 700;

		&:focus {
			border-radius: 24px;
			border: 3px solid #292929;
			outline: none;
		}

		.icon {
			margin-right: 0.8rem;
			font-size: 1rem;
		}
	}

	.saleNote {
		height: 2rem;
		width: 40%;
	}
}

.mobilecatalogue {
	height: 84%;
	width: 100%;
	position: absolute;
	top: 16%;
	display: block;
	padding-top: 0.1rem;
	z-index: 1;

	&__lots {
		height: 100%;
		width: 100%;
		display: block;
		position: relative;

		&.loading {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.mobilecatalogue__row {
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	&__container {
		height: 90%;
		width: 45%;
	}
}

.mobilecatalogue__lot.lotcard__container {
	position: relative;
	width: 40%;
	height: 30vh;
	box-shadow: 0px 2px 4px #00000029;
	margin: 1rem 0;

	&.loading {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lotcard {
		padding: 0.5rem;
		height: 100%;
	}

	.lotcard__details,
	.lotcard__title,
	.lotcard__estimate {
		width: 90%;
	}
}

@import "styles/variables";

.lotsfeed {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
	float: right;

	&.layout_2 {
		padding: 0 1rem;
	}

	.transition-mask {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: var(--primary-color-light);
		z-index: 10;
	}

	&__browser__border {
		position: absolute;

		&.top {
			top: 0;
			left: 1%;
			z-index: 2;
			width: 98%;

			&.layout_2 {
				left: 5%;
				width: 90%;
			}
		}

		&.right {
			height: 99.9%;
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
			width: 1vw;
			z-index: 4;
			left: 99%;

			&.layout_2 {
				left: 95%;
			}
		}

		&.left {
			height: 99.8%;
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;
			width: 1vw;
			z-index: 4;
		}

		&.bottom {
			bottom: -2vh;
			left: 0;
			z-index: 2;
			height: 4vh;

			&.layout_0 {
				width: 85.1vw;
			}

			&.layout_1 {
				width: 49vw;
			}

			&.layout_2 {
				width: 20vw;
			}
		}
	}

	&__browser__mask {
		z-index: 1;
		position: absolute;
		display: none;

		&.right {
			height: 100%;
			width: 1vw;
			z-index: 3;

			&.layout_0 {
				left: 99%;
			}

			&.layout_1 {
				left: 48vw;
			}

			&.layout_2 {
				left: 20vw;
			}
		}

		&.left {
			height: 100%;
			width: 1vw;
			z-index: 3;

			&.layout_0 {
				left: 99%;
			}

			&.layout_1 {
				left: 48vw;
			}

			&.layout_2 {
				left: 20vw;
			}
		}
	}

	&__browser__filter {
		position: absolute;
		border-radius: 100%;
		box-shadow: 0px 2px 8px #00000029;
		z-index: 4;
		right: 1vw;
		top: 1vh;

		&.closed {
			height: 5vh;
			width: 5vh;
			border-radius: 100%;
		}

		&.open {
			height: 5vh;
			width: 30vw;
			padding: 0.5vh 0.5vw;
			transition: width 2s;
			border-radius: 24px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			.filter__close__button,
			.filter__search__button {
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				height: 3vh;
				width: 3vh;
				border: solid 1px #d7dceb;
				border-radius: 100%;
				background-color: white;
			}

			.filter__instruction {
				margin: 0 1vw;
			}

			.filter__button {
				padding: 0.5vh 1vw;
				margin: 0 0.5vw;
				border: none;
				background-color: white;
				border-radius: 8px;

				&.active {
					background-color: #d7dceb;
				}
			}

			.filter__search__input {
				width: 25vw;
				height: 3vh;
				border-radius: 12px;
				border: solid 1px #d7dceb;
				text-align: right;
				padding: 0 1vw;
			}
		}
	}

	&__browser {
		height: 105%;
		padding: 0vh 0.4vw;
		transition: width 0.2s;

		width: 100%;

		&.placeholder {
			background-color: white;
			border: solid 1px #d7dceb;
			border-radius: 12px;
		}
	}
}

// SLICK STYLING
.lotsfeed__browser {
	&.transitioning {
		visibility: hidden;
	}

	.slick-list,
	.slick-slider,
	.slick-track {
		position: relative;
		display: block;
	}
	.slick-loading .slick-slide,
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slider {
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: 0;
	}
	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-list,
	.slick-slider .slick-track {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		top: 0;
		left: 0;
	}
	.slick-track:after,
	.slick-track:before {
		display: table;
		content: "";
	}

	.slick-track:after {
		clear: both;
	}

	.slick-slide {
		// display:none;
		float: left;
		height: 100%;
		min-height: 1px;
		outline: none !important;
	}

	[dir="rtl"] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-vertical .slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
	/*# sourceMappingURL=slick.min.css.map */

	.slick-track {
		height: 95% !important;
		border-radius: 12px;
		// background-color: white;
	}

	.slick-list,
	.slick-track {
		height: 100%;
		// background-color: white;

		/* THIS IS A HACK FOR UNKNOWN div TAG UNDER EVERY LOT ELEMENT IN BROWSER SLICK TRACK */
		div:not([class]) {
			width: 100%;
			height: 100%;
		}
	}
}

.lotsfeed__browser .slick-arrow {
	height: 40px;
	width: 40px;
	border-radius: 100%;
	position: absolute;
	top: 43%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.slick-prev {
		left: -20px;
		z-index: 4;
	}

	&.slick-next {
		right: -20px;
		z-index: 4;
	}
}

.lotsfeed__browser.layout_2 .slick-arrow {
	&.slick-prev {
		left: -5px;
	}

	&.slick-next {
		right: -5px;
	}
}

.lotsfeed__browser.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	color: gray;

	.spinner {
		margin-left: 1rem;
	}
}

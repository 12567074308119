.container {
	height: 10%;
	width: 100%;
	padding: 0 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	opacity: 0.9;
}

.subtitle {
	margin-left: 1rem;
	font-size: 0.8rem;
	opacity: 0.7;
}

.track {
	position: relative;
	height: 2.2rem;
	width: 4rem;
	background: var(--primary-color-light);
	border: solid 1px var(--secondary-color-dark);
	border-radius: 60px;
	transition: all 0.3s;

	&.open {
		background: var(--primary-color-dark);
	}
}

.slider {
	position: absolute;
	height: 1.75rem;
	width: 1.75rem;
	border-radius: 100%;
	background: var(--primary-color-dark);
	top: 0.16rem;
	left: 0.3rem;
	transition: all 0.3s;

	&.open {
		background: var(--primary-color-light);
		left: 1.8rem;
	}
}

.lots {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	--transitiontime: 600ms;
	--transitiontype: ease-out;
}

.track {
	position: absolute;
	padding-bottom: 1rem;
	height: 100%;
	width: 100%;
	display: flex;
	left: 0;
	will-change: transform;
}

.transitionNext {
	width: 200%;
	animation: slide-from-right var(--transitiontime) var(--transitiontype);
	@keyframes slide-from-right {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-50%);
		}
	}
}

.transitionPrev {
	width: 200%;
	animation: slide-from-left var(--transitiontime) var(--transitiontype);
	@keyframes slide-from-left {
		0% {
			transform: translateX(-50%);
		}
		100% {
			transform: translateX(0);
		}
	}
}

.frame {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-template-rows: repeat(3, minmax(0, 1fr));
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	padding: 0 2rem;
}

.item {
	display: flex;
	align-items: center;
	justify-content: center;
}

.filter {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 2;
	border-radius: 24px;
}

.openbutton {
	font-size: 1rem;
	border: none;
	border-radius: 100%;
	box-shadow: 0px 2px 8px #00000029;
	height: 5vh;
	width: 5vh;
	background: var(--primary-color-light);

	&:hover {
		background: var(--primary-color-dark);
		color: var(--primary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.open {
	background: var(--primary-color-light);
	box-shadow: 0px 2px 8px #00000029;
	border-radius: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	padding: 1rem 2rem;
	padding-right: 0.5rem;
	height: 5vh;
}

.filterbutton {
	background: var(--primary-color-light);
	border: none;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 24px;

	&.active {
		background: var(--primary-color-dark);
		color: var(--primary-color-light);
	}

	&:hover:not(.active) {
		background: var(--secondary-color-dark);
	}

	&:active {
		transform: scale(1.05);
	}
}

.searchbutton,
.closebutton {
	background: var(--primary-color-light);
	border: 1px solid var(--secondary-color-dark);
	border-radius: 100%;
	padding: 0.5rem 0.7rem;
	font-size: 1rem;
	&:hover {
		color: var(--primary-color-light);
		background: var(--primary-color-dark);
	}

	&:active {
		transform: scale(1.05);
	}
}

.search {
	background: var(--primary-color-light);
	border: none;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 24px;
	box-shadow: 0px 2px 8px #00000029;
	min-width: 20rem;
}

.wrapper {
	height: 50%;
	width: 100%;
	position: relative;
	// margin-bottom: 3%;
}

.wrapperviewer {
	height: 100%;
	width: 100%;
	position: relative;
}

.shadow {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 90;
	background: black;
	opacity: 0.3;
}

.overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.icon {
	color: white;
	font-size: 3rem;
	opacity: 1;
}

.videofeed {
	height: 100%;
	overflow: hidden;

	iframe {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	img {
		width: 100%;
		height: 100%;
	}
}

.videocontainer {
	position: relative;
	height: 100%;
}

.placeholder {
	background-color: black;
	color: white;
	height: 315px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ticker {
	position: absolute;
	width: 100%;
	color: white;
	top: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	overflow: hidden;

	p {
		display: inline-block;
		padding-left: 200%;
		animation: marquee 20s linear infinite;
	}
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

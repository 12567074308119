.currentlot {
	height: 100%;
	width: 100%;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
	// box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
	border: solid 1px var(--secondary-color-dark);
	background: var(--primary-color-light);
}

.header {
	height: 8%;
	width: 100%;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: var(--regular-18-font-size);
}

.discover {
	height: 9.5%;
	width: 100%;
	border-radius: 0;
	border-top: solid 1px var(--secondary-color-dark);
	position: absolute;
	bottom: -1px;
	display: flex;
	align-items: center;
	font-weight: 400;
	justify-content: space-evenly;
}

.text {
	width: 100%;
	padding: 0 1vw;
	display: flex;
	justify-content: space-between;
	font-weight: 400;
}

.auctiontitle {
	max-width: 60%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon {
	margin-right: 1rem;
}

.details {
	height: 30%;
	width: 90%;
	margin-left: 5%;
	overflow: hidden;
}

// .tabs {
// 	height: 15%;
// 	font-size: var(--regular-16-font-size);
// 	width: 100%;
// }

// .tabitem {
// 	height: 100%;
// 	border: none;
// 	margin-right: 5%;
// 	color: #20273d;
// 	opacity: 50%;
// 	outline: none;
// 	border-bottom: 2px solid rgba(0, 0, 0, 0);
// 	font-weight: 600 !important;
// 	letter-spacing: 0.016vw;
// 	opacity: 100%;
// 	border-bottom: 2px solid;
// }

.detailbody {
	margin-top: 1%;
	height: 75%;
	overflow-y: auto;
	font-weight: 400;
	padding-right: 2%;
	font-size: var(--small-14-font-size);
	scrollbar-color: var(--secondary-color-dark) var(--background-color);
	scrollbar-width: thin;
}

.detailtitle {
	margin-bottom: 2%;
	font-weight: 500;
	letter-spacing: 0.0093vw;
}

.images {
	height: 50%;
	width: 100%;
	margin: 2vh 0vh 2vh 0vh;
	display: flex;
}

.mainimagewrapper {
	width: 70%;
	height: 100%;
}

.mainimage {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	padding: 0 1.5rem;

	img {
		max-height: 100%;
		max-width: 100%;
	}
}

.spacer {
	height: 100%;
	width: 1px;
	background: var(--secondary-color-dark);
}

.thumbnails {
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 1.5rem;
	margin-right: 0.5rem;
}

.thumbnailbutton {
	height: 30%;
	width: 100%;
	border: none;
	background-color: white;
	margin-bottom: 2vh;
}
.thumbnail {
	height: 100%;
	width: 100%;
	opacity: 0.5;
	transform: scale(0.8);

	img {
		max-height: 100%;
		max-width: 100%;
	}
	&:hover,
	&.active {
		transform: scale(1);
		opacity: 1;
	}
}

.thumbnail:hover {
	transform: scale(1);
	opacity: 1;
}
.active {
	transform: scale(1);
	opacity: 1;
}

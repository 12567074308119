@import "styles/typekit.scss";


html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: border-box;
}


body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

button {
    cursor: pointer;
    padding: 0;
}
.autobidpanel {
	position: absolute;
	bottom: -100%;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background: var(--primary-color-light);
	z-index: 11;
	border-top: 1px solid var(--secondary-color-dark);
	transition-duration: 0.3s;
	border-radius: 12px;
}

.active {
	transform: translateY(-100%);
}

.instruction {
	width: 90%;
	text-align: left;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	letter-spacing: 0.512px;
}

.container {
	height: 20%;
	width: 90%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.symbol {
	position: absolute;
	left: 1rem;
	font-weight: 600;
	font-size: 24px;
}

.input {
	height: 100%;
	width: 100%;
	border-radius: 12px;
	border: none;
	background-color: var(--secondary-color-light);
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: right;
	padding-right: 1rem;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 150% */
}

.controls {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.info {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 12px 32px;
	min-height: 3.2rem;
}

.deleteButton {
	width: 45%;
	display: flex;
	padding: 12px 32px;
	min-height: 3.2rem;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 360px;
	background: var(--primary-color-light);
	border: 1.5px solid var(--primary-color-dark);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	letter-spacing: 0.5px;
	text-transform: uppercase;

	&:hover {
		outline: 1px solid var(--primary-color-dark);
	}
}

.confirmButton {
	width: 45%;
	display: flex;
	padding: 12px 32px;
	min-height: 3.2rem;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 360px;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px; /* 150% */
	letter-spacing: 0.5px;
	text-transform: uppercase;
	border: 1.5px solid var(--secondary-color-dark);
	&:hover {
		outline: 2px solid var(--secondary-color-dark);
	}
}

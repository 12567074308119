.container {
	height: 100%;
	width: 25%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.button {
	background-color: var(--primary-color-light);
	border-radius: 8px;
	width: 100%;
	height: 50%;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px var(--secondary-color-dark);
	// font-size: var(--small-12-font-size);
	&:active {
		transform: scale(1.05);
	}
}

.active {
	background: var(--accent-color-1);
	color: var(--primary-color-light);
}

.success {
	background: var(--admin-accent-1);
	color: var(--primary-color-light);
}

.error {
	background: var(--warning-color);
	color: var(--primary-color-light);
}

.label {
	// font-size: var(--small-14-font-size);
	text-align: center;
}

@import "styles/variables";

.rootadmin {
    height: 100vh;
    width: 100vw;
    // background-color: #F5F6FA;
    // margin-top: 50px;
    display: flex;
    overflow-y: scroll;

    &__main {
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__home {
        height: 100%;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-size: 24px;
        margin-bottom: 5vh;
    }

    &__input {
        height: 40vh;
        width: 50vw;
    }
}
.incrementInfo {
	position: absolute;
	bottom: 10%;
	height: 1rem;
	// width: 100%;
	opacity: 0.6;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
}

.flipdaterContainer {
	height: 1rem;
	position: relative;
	width: 5rem;
	margin-left: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@import 'styles/variables';

.bidselector {
	height: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__col-1 {
		width: 55%;
		height: 100%;
		display: flex;
		justify-content: space-evenly;
	}

	&__col-2 {
		width: 40%;
		height: 100%;
		display: flex;
		justify-content: center;
	}

	&__floor,
	&__online,
	&__phone,
	&__streambid {
		height: 100%;
		width: 30%;
		box-shadow: 0 2px 5px #bababa;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		border-radius: 12px;
		font-weight: 500;

		&__container {
			height: 3rem;
			width: 3rem;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
		}

		&__title {
			margin-top: 1rem;
			font-weight: 600;
			z-index: 2;
		}

		&:hover {
			background-color: var(--secondary-color-light);
			box-shadow: 0 2px 5px #e8e8e8;

			&__container {
				background-color: white;
			}

			.highlighter__mask {
				background: var(--secondary-color-light);
			}
		}

		&:active {
			background-color: #e0e7ff;
			transform: scale(1.05);
		}

		&:focus {
			border-radius: 12px;
			border: 3px solid #292929;
			outline: none;
		}
	}

	&__streambid {
		width: 80%;
		margin-left: 2%;
		box-shadow: none;
		background: var(--primary-color-light);
		text-transform: uppercase;

		.highlighter__container {
			position: relative;
			overflow: hidden;
			height: 5rem;
			width: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
		}

		.highlighter {
			position: absolute;
			height: 4rem;
			width: 4rem;
			border-radius: 100%;
			background-color: blueviolet;
			top: -17%;
			left: -17%;
		}

		.highlighter__mask {
			height: 3rem;
			width: 3rem;
			position: absolute;
			background-color: white;
			overflow: hidden;

			.highlighter__inner {
				position: absolute;
				height: 4rem;
				width: 4rem;
				border-radius: 100%;
				background-color: #33d67c;
			}

			&.one {
				top: -0.65rem;
				right: -0.65rem;

				.highlighter__inner {
					left: -3rem;
					bottom: -3rem;

					&::before {
						content: '';
						position: absolute;
						height: 4rem;
						width: 4rem;
						background-color: white;
						border-radius: 100%;
						left: -2rem;
						bottom: -2rem;
					}

					&.pulse {
						animation: pulse1 1s linear infinite;
						@keyframes pulse1 {
							0% {
								left: -3rem;
								bottom: -3rem;
							}
							50% {
								left: -1rem;
								bottom: -1rem;
							}
							100% {
								left: -3rem;
								bottom: -3rem;
							}
						}
					}
				}
			}

			&.two {
				bottom: -0.65rem;
				right: -0.65rem;

				.highlighter__inner {
					top: -3rem;
					left: -3rem;

					&::before {
						content: '';
						position: absolute;
						height: 4rem;
						width: 4rem;
						background-color: white;
						border-radius: 100%;
						left: -2rem;
						top: -2rem;
					}

					&.pulse {
						animation: pulse2 1s linear infinite;
						@keyframes pulse2 {
							0% {
								top: -3rem;
								left: -3rem;
							}
							50% {
								top: -1rem;
								left: -1rem;
							}
							100% {
								top: -3rem;
								left: -3rem;
							}
						}
					}
				}
			}

			&.three {
				bottom: -0.65rem;
				left: -0.65rem;

				.highlighter__inner {
					top: -3rem;
					right: -3rem;

					&::before {
						content: '';
						position: absolute;
						height: 4rem;
						width: 4rem;
						background-color: white;
						border-radius: 100%;
						top: -2rem;
						right: -2rem;
					}

					&.pulse {
						animation: pulse3 1s linear infinite;
						@keyframes pulse3 {
							0% {
								top: -3rem;
								right: -3rem;
							}
							50% {
								top: -1rem;
								right: -1rem;
							}
							100% {
								top: -3rem;
								right: -3rem;
							}
						}
					}
				}
			}

			&.four {
				top: -0.65rem;
				left: -0.65rem;

				.highlighter__inner {
					bottom: -3rem;
					right: -3rem;

					&::before {
						content: '';
						position: absolute;
						height: 4rem;
						width: 4rem;
						background-color: white;
						border-radius: 100%;
						bottom: -2rem;
						right: -2rem;
					}

					&.pulse {
						animation: pulse4 1s linear infinite;
						@keyframes pulse4 {
							0% {
								bottom: -3rem;
								right: -3rem;
							}
							50% {
								bottom: -1rem;
								right: -1rem;
							}
							100% {
								bottom: -3rem;
								right: -3rem;
							}
						}
					}
				}
			}
		}

		&__container {
			position: relative;
			// outline: solid 12px #d6338a;
			// box-shadow: 10px -10px 0px 0px #d6338a;

			&.wiggle {
				animation: wiggler 0.6s linear infinite;
			}

			@keyframes wiggler {
				0% {
					outline: solid 0px #33d67c;
				}

				50% {
					outline: solid 20px #33d67c;
				}

				100% {
					outline: solid 0px #33d67c;
				}
			}

			.bidselector__streambidnumber {
				position: absolute;
				height: 1.5rem;
				width: 1.5rem;
				top: -0.5rem;
				right: -0.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				background-color: rgb(184, 8, 8);
				color: white;
			}
		}

		&.active {
			background: var(--accent-color-2);

			&:hover {
				background: #44e68c;
			}

			.bidselector__streambid__title {
				color: #292929;
			}
		}

		&:disabled {
			background-color: white;
			&:hover {
				cursor: default;
				.bidselector__streambid__title {
					color: black;
					opacity: 0.3;
				}
			}
		}
	}
}

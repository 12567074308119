@import 'styles/variables';

.bids {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 65%;
	width: 100%;
	border-radius: 12px;
	padding-top: 5%;

	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 8px;
	transition-duration: 0s;

	// &:hover {
	//     overflow-y: scroll;
	//     padding-right: 0px;
	//     transition-duration: 0s;
	// }
}

.adminpages {
	height: 100%;
	width: 100%;
	display: flex;
	position: relative;
	// overflow: hidden;

	&__main {
		height: 100%;
		width: 90%;
		margin-left: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		// padding: 1rem 1rem 0 1rem;
	}

	&__home {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}

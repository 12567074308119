.infoPanel {
	width: 100%;
	height: 7%;
	display: flex;
	align-items: center;
	border: solid 1px var(--secondary-color-dark);
	justify-content: space-between;
	border-radius: 12px;
	padding: 0 1rem;
	font-size: var(--large-20-font-size);
}

.saleNote {
	padding: 0.4rem;
	min-width: 40%;
	border: solid 1px var(--secondary-color-dark);
	border-radius: 8px;
}

.warning {
	border: solid 2px var(--warning-color);
	-webkit-animation: blink 0.75s step-end infinite;
	animation: blink 0.75s step-end infinite;

	@-webkit-keyframes blink {
		from,
		to {
			border: solid 2px var(--warning-color);
		}
		50% {
			border: solid 1px var(--secondary-color-dark);
		}
	}
	@keyframes blink {
		from,
		to {
			border: solid 2px var(--warning-color);
		}
		50% {
			border: solid 1px var(--secondary-color-dark);
		}
	}
}

.bidderinterface {
	--slide-time: 0.5s;
	--easing: ease-in-out;
}

.bidderinterface__sidepanel {
	&.layout-0-1 {
		width: 45%;
		top: 0;
		animation: sidepanel-0-1 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-0-1 {
			0% {
				left: -45%;
			}
			100% {
				left: 0%;
			}
		}

		.wishlist {
			display: block;
		}

		.catalogue {
			display: none;
		}
	}

	&.layout-0-2 {
		width: 65%;
		top: 0;
		animation: sidepanel-0-2 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-0-2 {
			0% {
				left: -65%;
			}
			100% {
				left: 0%;
			}
		}

		.catalogue {
			display: block;
		}

		.wishlist {
			display: none;
		}
	}

	&.layout-1-2 {
		top: 0;
		animation: sidepanel-1-2 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-1-2 {
			0% {
				left: 0%;
				width: 45%;
			}
			100% {
				left: 0%;
				width: 65%;
			}
		}

		.catalogue {
			display: block;
		}

		.wishlist {
			display: none;
		}
	}

	&.layout-2-1 {
		top: 0;
		animation: sidepanel-2-1 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-2-1 {
			0% {
				left: 0%;
				width: 65%;
			}
			100% {
				left: 0%;
				width: 45%;
			}
		}

		.catalogue {
			display: none;
		}
		.wishlist {
			display: block;
		}
	}

	&.layout-1-0 {
		width: 45%;
		top: 0;
		animation: sidepanel-1-0 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-1-0 {
			0% {
				left: 0%;
			}
			100% {
				left: -45%;
			}
		}

		.wishlist {
			animation: wishlist-1-0 var(--easing) var(--slide-time);
			@keyframes wishlist-1-0 {
				100% {
					display: none;
				}
			}
		}
	}

	&.layout-2-0 {
		width: 65%;
		top: 0;
		animation: sidepanel-2-0 var(--easing) var(--slide-time);
		animation-fill-mode: forwards !important;

		@keyframes sidepanel-2-0 {
			0% {
				left: 0%;
			}
			100% {
				left: -65%;
			}
		}

		.wishlist {
			display: none;
		}
	}
}

.bidderinterface__container {
	&.layout-0-1 {
		width: 54%;
		animation-fill-mode: forwards !important;
		animation: container-0-1 var(--easing) var(--slide-time);
		@keyframes container-0-1 {
			0% {
				margin-left: 5%;
			}
			100% {
				margin-left: 45%;
			}
		}

		.currentlot {
			animation: anim-0-1 var(--easing) var(--slide-time);
			animation-fill-mode: forwards !important;
			position: absolute;
			transition: none;
			top: 1%;
			left: -70%;
			width: 62%;

			@keyframes anim-0-1 {
				0% {
					opacity: 1;
					left: -70%;
				}
				75% {
					opacity: 0.25;
				}
				100% {
					opacity: 0;
					left: 0%;
				}
			}
		}

		.bidderinterface__container__row-1 {
			animation: row-0-1 var(--easing) var(--slide-time);
			@keyframes row-0-1 {
				0% {
					margin-left: 32.5%;
				}
				100% {
					margin-left: 0%;
				}
			}
		}
	}

	&.layout-0-2 {
		width: 34%;
		animation-fill-mode: forwards !important;
		animation: container-0-2 var(--easing) var(--slide-time);
		@keyframes container-0-2 {
			0% {
				margin-left: 5%;
			}
			100% {
				margin-left: 65%;
			}
		}

		.wishlist {
			// display: none !important;
		}

		.currentlot {
			animation: anim-0-2 var(--easing) var(--slide-time);
			animation-fill-mode: forwards !important;
			position: absolute;
			transition: none;
			top: 1%;
			left: -100%;
			width: 100%;

			@keyframes anim-0-2 {
				0% {
					opacity: 1;
					left: -100%;
				}
				75% {
					opacity: 0.25;
				}
				100% {
					opacity: 0;
					left: 0%;
				}
			}
		}

		.bidderinterface__container__row-1 {
			animation: row-0-2 var(--easing) var(--slide-time);
			@keyframes row-0-2 {
				0% {
					margin-left: 32.5%;
				}
				100% {
					margin-left: 0%;
				}
			}
		}

		.bidsfeed {
			animation-fill-mode: forwards !important;
			animation: bidsfeed-0-2 var(--easing) var(--slide-time);
			position: absolute;
			top: 2%;
			width: 70%;

			@keyframes bidsfeed-0-2 {
				0% {
					opacity: 1;
					right: -65%;
				}
				50% {
					opacity: 0.25;
				}
				99% {
					right: -100%;
					opacity: 0;
				}
				100% {
					right: 0;
				}
			}
		}
	}

	&.layout-1-2 {
		width: 34%;
		animation-fill-mode: forwards !important;
		animation: container-1-2 var(--easing) var(--slide-time);
		@keyframes container-1-2 {
			0% {
				margin-left: 5%;
			}
			100% {
				margin-left: 65%;
			}
		}

		.currentlot {
			display: none;
		}

		.bidderinterface__container__row-1 {
			animation: row-1-2 var(--easing) var(--slide-time);
			@keyframes row-1-2 {
				0% {
					margin-left: 32.5%;
				}
				100% {
					margin-left: 0%;
				}
			}
		}

		.bidsfeed {
			animation-fill-mode: forwards !important;
			animation: bidsfeed-1-2 var(--easing) var(--slide-time);
			position: absolute;
			top: 2%;
			width: 70%;

			@keyframes bidsfeed-1-2 {
				0% {
					opacity: 1;
					right: -65%;
				}
				50% {
					opacity: 0.25;
				}
				100% {
					right: -100%;
					opacity: 0;
				}
			}
		}
	}

	&.layout-2-1 {
		width: 55%;
		animation-fill-mode: forwards !important;
		animation: container-2-1 var(--easing) var(--slide-time);
		@keyframes container-2-1 {
			0% {
				margin-left: 50%;
			}
			100% {
				margin-left: 45%;
			}
		}

		.currentlot {
			display: none;
		}

		.bidderinterface__container__row-1 {
			animation: row-2-1 var(--easing) var(--slide-time);
			@keyframes row-2-1 {
				0% {
					margin-left: 32.5%;
				}
				100% {
					margin-left: 0%;
				}
			}
		}

		.bidsfeed {
			animation-fill-mode: forwards !important;
			animation: bidsfeed-2-1 var(--easing) var(--slide-time);

			@keyframes bidsfeed-2-1 {
				0% {
					opacity: 0;
				}
				50% {
					opacity: 0.25;
				}
				100% {
					opacity: 1;
				}
			}
		}
	}

	&.layout-1-0 {
		animation-fill-mode: forwards !important;
		animation: container-1-0 var(--easing) var(--slide-time);
		@keyframes container-1-0 {
			0% {
				margin-left: 45%;
			}
			100% {
				margin-left: 5%;
			}
		}

		.bidsfeed {
			animation-fill-mode: forwards !important;
			animation: bidsfeed-1-0 var(--easing) var(--slide-time);
			@keyframes bidsfeed-1-0 {
				0% {
					margin-right: 20%;
				}
				100% {
					margin-right: 0%;
				}
			}
		}

		.currentlot {
			animation: anim-1-0 var(--easing) var(--slide-time);
			animation-fill-mode: forwards;

			@keyframes anim-1-0 {
				0% {
					margin-right: -25%;
					opacity: 0;
				}
				75% {
					opacity: 0.25;
				}
				100% {
					opacity: 1;
					margin-right: 0%;
				}
			}
		}
	}

	&.layout-2-0 {
		animation-fill-mode: forwards !important;
		animation: container-2-0 var(--easing) var(--slide-time);
		@keyframes container-2-0 {
			0% {
				margin-left: 65%;
			}
			100% {
				margin-left: 5%;
			}
		}

		.bidsfeed {
			animation-fill-mode: forwards !important;
			animation: bidsfeed-2-0 var(--easing) var(--slide-time);
			@keyframes bidsfeed-2-0 {
				0% {
					padding-left: 20%;
					opacity: 0;
				}
				100% {
					padding-left: 0%;
					opacity: 1;
				}
			}
		}

		.currentlot {
			animation: anim-2-0 var(--easing) var(--slide-time);
			animation-fill-mode: forwards;

			@keyframes anim-2-0 {
				0% {
					margin-right: -25%;
					opacity: 0;
				}
				75% {
					opacity: 0.25;
				}
				100% {
					opacity: 1;
					margin-right: 0%;
				}
			}
		}
	}
}

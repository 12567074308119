.sidebar {
	height: 100%;
	width: 100%;
	background: var(--primary-color-dark);
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.logo {
	height: 3.5vw;
	width: 100%;
	margin: 2vh 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 3.5vw;
		width: 3.5vw;
		max-height: 100px;
		max-width: 100px;
	}
}

.spacer {
	width: 56px;
	height: 1px;
}

.button {
	padding: 0.5rem 1.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: var(--regular-16-font-size);
	border: none;
	color: var(--primary-color-light);
	background: var(--primary-color-dark);
	border-top: solid 10px var(--primary-color-dark);
	border-right: solid 6px var(--primary-color-dark);
	border-bottom: solid 10px var(--primary-color-dark);
	text-decoration: none;
	font-weight: 400;
}

.iconwrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3vw;
	width: 3vw;
	margin-bottom: 0.5rem;
}

.icon {
	font-size: var(--large-20-font-size);
}

.circle {
	position: absolute;
	opacity: 0.2;
	height: 0;
	width: 0;
	border-radius: 100%;
	background: var(--primary-color-light);
	transition: all 300ms;
}

.buttonactive {
	border-right: solid 6px var(--background-color);
}

.button:hover .iconwrapper .circle,
.active {
	height: 100%;
	width: 100%;
}

@import 'styles/variables';

.biddermobile__mobilebidpanel {
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2% 2% 0;

	.mobilebidpanel {
		&__values {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			.autobidcurrent {
				width: 100%;
				padding: 0.3rem;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.container {
					display: flex;
					align-items: center;
				}

				.delete {
					padding: 0.5rem 1.5rem;
					border-radius: 24px;
					font-weight: 600;
				}
			}
		}
	}
}

// &__autobidtogglecontrol {
//     .switch {
//         position: relative;
//         display: inline-block;
//         width: 3rem;
//         height: 1.5rem;
//     }

//     /* Hide default HTML checkbox */
//     .switch input {
//         opacity: 0;
//         width: 0;
//         height: 0;
//     }

//     /* The slider */
//     .slider {
//         position: absolute;
//         cursor: pointer;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-color: #ccc;
//         -webkit-transition: .4s;
//         transition: .4s;
//     }

//     .slider:before {
//         position: absolute;
//         content: "";
//         height: 20px;
//         width: 20px;
//         left: 2px;
//         bottom: 2px;
//         background-color: white;
//         -webkit-transition: .4s;
//         transition: .4s;
//     }

//     input:checked + .slider {
//         background-color: #345FDB;
//     }

//     input:focus + .slider {
//         box-shadow: 0 0 1px #345FDB;
//     }

//     input:checked + .slider:before {
//         -webkit-transform: translateX(26px);
//         -ms-transform: translateX(26px);
//         transform: translateX(26px);
//     }

//     /* Rounded sliders */
//     .slider.round {
//         border-radius: 34px;
//     }

//     .slider.round:before {
//         border-radius: 50%;
//     }
// }

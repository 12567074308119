@import "styles/variables";


.biddermobile {
    &__autobid {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__autobidmain {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1vh; 
        margin-top: 1%;
    }

    &__autobidcontrol {
        height: 3rem;
        width: 3rem;
        font-size: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 12px;   
    }

    .bidlive__title {
        position: absolute;
        left: 1rem;
    }

    .bidlive__cancel {
        position: absolute;
        right: 1rem;
    }

    &__autobidinput {
        width: 70%;
        text-align: center;
        padding: 2vh 0vw;
        // background-color: $secondary-color-light;
        border-radius: 12px;
        outline: none;
        border: none;
        font-size: 1.2rem;
    
        &.closed {
            width: 100%;
        }
    }

    &__autobidincrement {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        font-size: 0.9rem; 
        margin-bottom: 2%;

        .icon.increment__waiting {
            font-size: 1rem;
        }
    }
}

.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: var(--primary-color-light);
	position: relative;
	z-index: 7;

	height: 100%;
	width: 100%;
	min-width: 900px;
	overflow: hidden;
	padding: 1%;
	border-radius: 12px;
}

.header {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.closeButton {
	width: 3rem;
	height: 3rem;
	padding: 0;
	border-radius: 100%;
	border: none;
	font-size: 1.2rem;
	color: var(--primary-color-light);
	background: var(--primary-color-dark);
}

.body {
	height: 65%;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	font-weight: 500;
	letter-spacing: 0.016vw;
}

.mainImage {
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 100%;
		max-width: 100%;
	}
}

.thumbnails {
	height: 100%;
	width: 10%;
	margin-left: 1rem;
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	scrollbar-color: var(--secondary-color-dark) var(--background-color);
	scrollbar-width: thin;
}

.thumbnailButton {
	border: none;
	width: 100%;
	height: 25%;
}

.thumbnail {
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		height: auto;
		width: 100%;
	}

	&:hover {
		border: solid 1px var(--secondary-color-dark);
	}
}

.details {
	width: 40%;
	margin-left: 2vw;
	overflow-y: auto;
	padding-right: 0.5vw;
	font-size: 1rem;
	scrollbar-color: var(--secondary-color-dark) var(--background-color);
	scrollbar-width: thin;
}

.info {
	display: flex;
}

.lotNumber {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.itemSold {
	color: red;
}

.itemWon {
	color: var(--admin-accent-1);
}

.itemTitle {
	margin-top: 2vh;
	text-transform: uppercase;
	letter-spacing: 0.007vw;
	font-weight: 500;
}

.likes {
	position: relative;
	margin-left: 2vw;
	cursor: pointer;
}

.bookmark {
	font-size: 6vh;
	color: var(--secondary-color-dark);
}

.star {
	position: absolute;
	left: 0;
	right: 0;
	top: 0.5vh;
	margin: auto;
	color: black;
	text-align: center;
}

.starActive {
	color: red;
}

.likeCount {
	position: absolute;
	left: 0;
	right: 0;
	top: 2.6vh;
	margin: auto;
	text-align: center;
	font-size: 0.8rem;
}

.estimate {
	margin-top: 2vh;
	font-weight: 500;
	letter-spacing: 0.016vw;
}

.description {
	margin-top: 2vh;
	font-weight: 400;
	letter-spacing: 0.016vw;
}

.footer {
	height: 12%;
	width: 100%;
	// height: 8.19vh;
	box-shadow: 0px 0px 10px 2px #00000014;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	margin-top: 3vh;
	// padding: 1.48vh 0.83vw;
}

.hidden {
	visibility: hidden;
}

.container {
	height: 1.5rem;
	width: 3rem;
	background: var(--primary-color-light);
	border: solid 1px var(--secondary-color-dark);
	border-radius: 40px 40px 40px 40px;
	position: relative;
	transition: all ease-out 0.5s;
	cursor: pointer;

	&.isActive {
		background: var(--accent-color-1);
	}
}

.slider {
	height: 1.1rem;
	width: 1.1rem;
	border-radius: 100%;
	background: var(--accent-color-1);
	position: absolute;
	top: 0.15rem;
	left: 0.15rem;
	transition: all ease-out 0.5s;

	&.isActive {
		background: var(--primary-color-light);
		left: 1.5rem;
	}
}

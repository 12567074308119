.catalogue__nav {
	height: 5%;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	justify-content: space-between;

	.back {
		padding: 0.5rem 1rem;
		border-radius: 12px;
		border: solid 1px var(--secondary-color-dark);
		background: var(--primary-color-light);

		&:hover {
			color: var(--primary-color-light);
			background: var(--primary-color-dark);
		}
	}

	.close {
		height: 2rem;
		width: 2rem;
		border-radius: 100%;
		border: none;
		color: var(--primary-color-light);
		background: var(--primary-color-dark);
	}
}

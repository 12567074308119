@import 'styles/variables';

.biddermobile__mobilebidbutton {
	width: 100%;
	height: 20%;
	position: relative;

	.leadbid {
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		width: 0%;
		background-color: var(--admin-accent-1);
		animation-fill-mode: forwards;
		border-radius: 24px;
		// z-index: 1;

		&.live {
			animation: leadbidentry 0.3s linear;
			animation-fill-mode: forwards;
			@keyframes leadbidentry {
				100% {
					width: 100%;
					left: 0%;
				}
			}
		}
	}

	.bidbutton__pulse {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: var(--accent-color-1);
		border-radius: 24px;
		animation: bidpulse 1.2s ease infinite;
		@keyframes bidpulse {
			0% {
				transform: scale(1, 1);
			}
			50% {
				opacity: 0.3;
			}
			100% {
				transform: scale(1.3);
				opacity: 0;
			}
		}
	}
}

.mobilebidbutton__action {
	width: 100%;
	height: 90%;
	color: var(--primary-color-light);
	border: none;
	border-radius: 40px;
	font-size: 20px;
	font-weight: 600;
	position: relative;
	z-index: 1;
	margin: 1% 0;
	background-color: var(--accent-color-1);
	border: solid 1px var(--secondary-color-dark);
	text-transform: uppercase;
	// &.lead {
	//     background-color: var(--admin-accent-1);
	// }

	&.half {
		width: 48%;
	}

	&.disabled {
		opacity: 0.5;
	}
}

.mobilebidbutton__title {
	height: 100%;
	width: 100%;
	z-index: 3;
}

.mobilebidbutton__confirming {
	width: 20%;
	height: 100%;
	border-radius: 24px;
	z-index: -1;
	position: absolute;
	top: 0px;
	left: 0px;
	animation: confirmingslider 5s infinite;
	background: var(--accent-offset-1);
}

@keyframes confirmingslider {
	0% {
		width: 20%;
	}

	50% {
		width: 100%;
	}

	0% {
		width: 20%;
	}
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22rem;
	height: 3rem;
}

.button {
	font-family: inherit;
	margin-left: 1rem;
	padding: 0.5rem 1rem;
	border: none;
	font-size: 1rem;
	background: var(--primary-color-light);
	border-radius: 12px;
}

.closed {
	background: var(--accent-color-1);
	color: var(--primary-color-light);
	font-weight: 600;
}

.draft {
	background: var(--admin-accent-1);
	color: var(--primary-color-light);
	font-weight: 600;
}

.published {
	background: var(--admin-accent-2);
	color: var(--primary-color-light);
	font-weight: 600;
}

.live {
	background: var(--warning-color);
	color: var(--primary-color-light);
	font-weight: 600;
}

@import 'styles/variables';

.header {
	height: 7.5%;
	width: 100%;
	color: white;
	display: flex;
	justify-content: center;
	position: relative;

	.header-container {
		width: 95%;
		height: 100%;
		max-width: 1920px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		font-weight: 700;
		letter-spacing: 0.004vw;
	}

	.header__portmessage .warning-icon {
		font-size: 1.5rem;
		margin-right: 1rem;
		color: yellow;
	}

	.header__portmessage__close {
		padding: 0.1rem 0.2rem;
		margin-left: 1rem;
	}

	.header__controls {
		width: 30%;
		height: 70%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header__controls__inner {
			width: 65%;
			display: flex;
			justify-content: space-between;
			// position: relative;

			a,
			button {
				height: 3rem;
				width: 3rem;
				position: relative;

				.header__controls__tooltip {
					visibility: hidden;
					position: absolute;
					bottom: -4.5rem;
					min-height: 3.4rem;
					min-width: 5rem;
					left: -40%;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--secondary-color-dark);
					padding: 0.5rem;
					border-radius: 8px;
					color: var(--primary-color-dark);
					text-transform: capitalize;
				}

				svg {
					font-size: 1.2rem;
				}

				&:hover {
					background: var(--secondary-color-dark);
					color: var(--primary-color-dark);
					.header__controls__tooltip {
						z-index: 10;
						visibility: visible;
					}
				}
			}

			button {
				border: none;
			}

			.conn_error {
				color: yellow;
				position: absolute;
				top: 0;
				right: 0;

				&.invert {
					color: var(--primary-color-dark);
				}
			}
		}

		.header__end,
		.header__pause {
			height: 90%;
			width: 30%;
			display: flex;
			align-items: center;
			border-radius: 24px;
			border: none;
			font-weight: 700;
			padding: 0 3%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;

			&__icon {
				font-size: 1rem;
				margin-right: 1rem;
			}
		}

		.header__end {
			color: white;
		}

		// .header__pause {
		//     color: $primary-color-dark;
		//     background-color: red;
		// }
	}
}

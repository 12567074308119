.container {
	height: 50%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
}

.spacer {
	border-top: 1px solid var(--secondary-color-dark);
	flex-grow: 1;
}

.body {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	opacity: 0.8;
}

.container {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 4vmin;
	background: var(--primary-color-light);
}

.row1 {
	position: relative;
	width: 100%;
	height: 49%;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.withdrawn {
	justify-content: center;
	font-size: 3vmin;
}

.video {
	height: 100%;
	width: 38%;

	.videofeed__container {
		height: 100% !important;
	}
}

.bidsFeed {
	height: 100%;
	width: 21%;
}

@media (max-width: 1200px) {
	.bidsFeed {
		display: none;
	}
	.currentLot {
		width: 60%;
	}
	.video {
		width: 48%;
	}
}

.lotsFeed {
	background: var(--primary-color-light);
	width: 100%;
	height: 35%;
	border-radius: 12px;
}

.container {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: var(--primary-color-light);
}

.video {
	height: 39%;
	width: 100%;
	background: black;
	position: relative;

	.liveNow {
		position: absolute;
		top: 2vh;
		left: 4vw;
		background: #edeff2;
		padding: 2vmin;
		font-size: 3vmin;
		font-weight: 700;
		border-radius: 4px;
		text-transform: uppercase;
		z-index: 99;

		.icon {
			color: var(--warning-color);
			margin-right: 1vmin;
		}
	}
}

.title {
	max-height: 15%;
	max-width: 90%;
	overflow: hidden;
	color: #000;
	font-family: Cormorant Garamond;
	font-size: 9.5vmin;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.25px;
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.lotsfeed {
	height: 39%;
	width: 100%;
	padding: 5% 25%;
}

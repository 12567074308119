.passbids {
	height: 95%;
	width: 100%;
	position: relative;

	.passbids__modal {
		position: fixed;
		height: 70%;
		width: 80%;
		top: 15%;
		left: 15%;
		background: var(--primary-color-light);
		border: solid 3px var(--secondary-color-dark);
		text-transform: capitalize;

		.modal__header {
			height: 10%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.close {
				position: absolute;
				left: 2%;
				border: none;
				padding: 0.5rem 1.5rem;
			}
		}
	}

	.passbids__header {
		height: 10%;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: capitalize;

		.passbids__back {
			position: absolute;
			left: 2%;
			border: none;
			padding: 0.5rem 1.5rem;
		}
	}

	.passbids__table__wrapper {
		width: 100%;
		overflow: auto;
		border: 1px solid #d7dceb;
	}

	.passbids__table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		border-style: hidden;
		font-weight: 400;

		thead {
			background-color: #d7dceb;

			tr {
				height: 3rem;
				text-align: left;
			}
		}

		tbody {
			background-color: #edf0f7;
		}

		tr {
			height: 4rem;
		}

		th,
		td {
			padding: 0.5rem 1rem;
		}
	}

	.passbids__showall {
		padding: 0.5rem 0.8rem;
		background-color: white;
		border: none;
		border-radius: 8px;
		text-transform: capitalize;

		&:hover {
			background-color: #3ab99c;
			color: white;
		}
	}
}

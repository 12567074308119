.lot {
	display: flex;
	align-items: center;
	width: 99%;
	height: 100%;
	padding: 0.2rem;
	overflow: hidden;
	position: relative;
	border: 1px solid rgba(187, 187, 187, 0);
}

.withdrawn {
	opacity: 0.6;
}

.imageWrapper {
	height: 100%;
	width: 10%;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.description {
	width: 50%;
	padding-left: 2%;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: var(--regular-16-font-size);
}

.lotNo {
	font-weight: 700;
}

.title {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 400;
}

.info {
	font-weight: 500;
	width: 20%;
	padding-left: 5%;
	color: var(--accent-color-1);
	font-size: var(--regular-16-font-size);
}

.estimate {
	position: absolute;
	right: 2%;
	text-align: right;
	text-transform: capitalize;
	font-size: var(--small-14-font-size);
}

.estimateValue {
	font-weight: 700;
	font-size: var(--regular-16-font-size);
}

.passedColor {
	color: var(--wishlist-color);
}

.soldColor {
	color: var(--admin-accent-1);
}

.isActive {
	border-radius: 12px;
	color: var(--accent-color-1);

	&:hover {
		.lot__action {
			display: none;
		}
	}
}

.sold {
	opacity: 0.38;
}

.hoverHighlight:hover {
	background-color: #d7dceb;
	border: 1px solid #bbbbbb;

	.setItem {
		opacity: 1;

		z-index: 99;
	}
}

.setItem {
	all: unset;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	border-radius: 10px;
	outline: none;
	opacity: 0;
	background: var(--primary-color-light);
	border: solid 1px var(--accent-color-1);
	color: var(--accent-color-1);
	padding: 0.4rem 0.8rem;
	font-size: var(--regular-16-font-size);

	&:active {
		transform: scale(1.05);
	}
}

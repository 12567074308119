.bidsfeed {
	height: 100%;
	border-radius: 12px;
	width: 100%;
	overflow: hidden;
	// box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
	border: solid 1px var(--secondary-color-dark);
	background-color: var(--primary-color-light);
}

.layout_1 {
	width: 38%;
}

.header {
	height: 8%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: var(--regular-18-font-size);
	letter-spacing: 0.016vw;
	color: var(--primary-color-light);
	background-color: var(--primary-color-dark);
	text-transform: uppercase;
}

.feed {
	position: relative;
	height: 92%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 8px;
	text-transform: capitalize;
	scrollbar-color: var(--secondary-color-dark) var(--background-color);
	scrollbar-width: thin;
}

.showFooter {
	height: 82%;
}

.animateSlide {
	transition: all 0.5s;
	padding-top: 3.2rem;
}

.footer {
	width: 100%;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	font-size: var(--small-14-font-size);
	font-weight: 400;
	border-top: solid 1px var(--secondary-color-dark);
	overflow: hidden;
}

.user {
	width: 40%;
	text-align: left;
	display: flex;
	align-items: center;

	.fa-user {
		margin-right: 0.5rem;
	}
}

.usertitle {
	margin-left: 0.5rem;
}

.paddle {
	width: 40%;
	text-align: right;
}

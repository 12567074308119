.catalogue__lots {
	height: 100%;
	width: 70%;
	display: grid;
	grid-template-columns: auto auto auto;
	justify-content: space-evenly;
	overflow-y: auto;

	.lotcard__container {
		height: 30vh;
		width: 10vw;
		margin: 1rem;
	}
}

.catalogue__lots__loading {
	height: 100%;
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: center;
}

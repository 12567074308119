.loteditform {
	// position: fixed;
	// height: 30rem;
	width: 100%;
	// bottom: 15%;
	background: var(--primary-color-light);

	border: solid 2px var(--secondary-color-dark);
	// overflow: auto;

	.nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		// height: 10%;
		// width: 100%;
		// position: relative;
		// top: 0;

		.nav__close {
			padding: 0.4rem 0.5rem;
			border: solid 1px var(--secondary-color-dark);
			background: var(--primary-color-light);
			// position: fixed;
			// text-transform: capitalize;

			.icon {
				margin-right: 1rem;
			}
		}

		.nav__status {
			// position: fixed;
			padding: 0.5rem 5rem;
			border-radius: 8px;
			background: var(--admin-accent-2);
			// left: 45%;
		}

		.nav__container {
			// position: fixed;
			// left: 90%;
		}

		.nav_changelot {
			padding: 0.4rem 0.5rem;
			border: solid 1px var(--secondary-color-dark);
			background: var(--primary-color-light);
			margin-right: 1rem;
		}
	}

	.main {
		// height: 89%;
		display: flex;

		.images {
			width: 40%;
			height: 40vh;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;

			.mainimage {
				height: 60%;
				width: 40%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 100%;
					width: auto;
				}
			}

			.thumbnails {
				height: 30%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				overflow-y: hidden;
				overflow-x: auto;

				.thumbnail__container {
					height: 100%;
					cursor: pointer;

					.thumbnail {
						height: 100%;
						img {
							height: 100%;
							width: auto;
						}
					}
				}
			}
		}

		.form {
			height: 100%;
			width: 60%;
			padding: 0 5%;
		}
	}
}

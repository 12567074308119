.container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.increment {
	height: 3rem;
	width: 3rem;
	border: 1px solid var(--secondary-color-dark);
	border-radius: 8px;
	background: var(--secondary-color-light);

	.icon {
		font-size: 1.5rem;
	}
}

.input {
	height: 3rem;
	width: 60%;
	// border: 1px solid var(--secondary-color-dark);
	border: none;
	border-radius: 8px;
	background: var(--secondary-color-light);
	text-align: center;
	font-size: 1.5rem;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 8%;
	padding: 0 0.5rem;
	background: var(--primary-color-light);
}

.toggle {
	height: 60%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 1rem;
	border: none;
	border-radius: 24px;
	background: var(--secondary-color-light);
}

.toggle_button {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	border-radius: 24px;
	text-transform: capitalize;
}

.active {
	color: var(--primary-color-light);
	background: var(--primary-color-dark);
}

.inactive {
	color: var(--primary-color-dark);
	background: var(--secondary-color-light);
}

.info {
	height: 90%;
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid var(--secondary-color-dark);
	text-transform: uppercase;
}

.current {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;

	.live {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.1rem 0.4rem;
		border-radius: 4px;
		color: var(--primary-color-light);
		background: var(--warning-color);

		.icon {
			margin-right: 0.2rem;
			font-size: 0.7rem;
		}
	}

	.askingPrice {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.dynamicform {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;

	.dynamicform__items {
		// height: 100%;
		// width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.dynamicform__item {
		margin: 1rem;
		min-width: 30%;

		&.checkbox {
			input {
				height: 1.5rem;
				width: 1.5rem;
			}
		}
	}

	.dynamicform__controls {
		// display: flex;
		// margin: 1rem;
	}

	.printout {
		position: fixed;
		right: 5rem;
		top: 10rem;
		border: solid 1px;
		background: #f6f8fa;
	}

	input,
	select {
		padding: 0.5rem;
		font-size: 16px;
		width: 100%;
		display: block;
		border-radius: 4px;
		border: 1px solid #ccc;
	}

	input:focus,
	select:focus {
		border-color: #007eff;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 0 3px rgba(0, 126, 255, 0.1);
		outline: none;
	}

	input.error,
	select.error {
		border-color: red;
	}

	label {
		font-weight: bold;
		display: block;
		margin-bottom: 0.5rem;
	}

	.input-feedback {
		color: red;
		margin-top: 0.25rem;
	}

	button {
		max-width: 150px;
		margin: 20px 0;
		padding: 12px 20px;
		border-style: none;
		border-radius: 5px;
		background-color: #08c;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
		font-size: 17px;
		font-weight: 500;
		color: #fff;
		cursor: pointer;
		outline: none;
		-webkit-appearance: none;
	}

	button:disabled {
		opacity: 0.5;
		cursor: not-allowed !important;
	}

	button + button {
		margin-left: 0.5rem;
	}

	button.outline {
		background-color: #eee;
		border: 1px solid #aaa;
		color: #555;
	}
}

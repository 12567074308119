@import 'styles/variables';

// * {
// 	outline: 1px solid red;
// }

html {
	height: 100vh; // fit with / without browser bar, but causes a jump
	// height: 100%; // freezes browswer bar- less space but no jump
	width: 100%;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	zoom: revert;
	background: var(--background-color);

	::first-letter {
		text-transform: capitalize;
	}
}

body {
	height: 100%;
}

#root {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.App {
	width: 100%;
	height: 100%;
	position: relative;
}

.App.mobile {
	position: fixed;
}

@media only screen and (max-width: 1500px) and (min-width: 768px) {
	.app-bidder {
		min-width: 1500px;
		overflow: auto;
	}
}

@media only screen and (max-width: 1200px) {
	.app-clerk,
	.app-admin {
		min-width: 1200px;
		overflow: auto;
	}
}

@media only screen and (max-height: 570px) {
	html {
		font-size: 14px;
		height: 99%;
		// width: 99%;
	}
	.desktop.app-admin,
	.desktop.app-clerk,
	.desktop.app-bidder {
		height: 99%;
		// background: greenyellow;
	}
}

.auctioneer .bidselector__streambid {
	height: 100%;
	width: 100%;
	border-radius: 0px;
	margin: 0;
	background: var(--primary-color-light);

	&:hover {
		background-color: var(--secondary-color-light);
		box-shadow: 0 2px 5px #e8e8e8;

		&__container {
			background-color: white;
		}

		.highlighter__mask {
			background: var(--secondary-color-light);
		}
	}

	&.active {
		background: var(--accent-color-2);

		&:hover {
			background: #44e68c;
		}

		.bidselector__streambid__title {
			color: #292929;
		}
	}

	&__container {
		position: relative;
		// outline: solid 12px #d6338a;
		// box-shadow: 10px -10px 0px 0px #d6338a;

		&.wiggle {
			animation: wiggler 1s linear infinite;
		}

		@keyframes wiggler {
			0% {
				outline: solid 0px #33d67c;
			}

			50% {
				outline: solid 5rem #33d67c;
			}

			100% {
				outline: solid 0px #33d67c;
			}
		}

		.bidselector__streambidnumber {
			position: absolute;
			height: 7rem;
			width: 7rem;
			top: -2rem;
			right: -2rem;
			font-size: 4rem !important;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			background-color: rgb(184, 8, 8);
			color: white;
		}
	}

	.highlighter__container {
		height: 20rem;
		width: 20rem;

		.highlighter__mask {
			height: 12rem;
			width: 12rem;

			&.one {
				top: -2.6rem;
				right: -2.6rem;

				.highlighter__inner {
					left: -12rem;
					bottom: -12rem;

					&::before {
						content: '';
						position: absolute;
						height: 16rem;
						width: 16rem;
						background-color: white;
						border-radius: 100%;
						left: -8rem;
						bottom: -8rem;
					}

					&.pulse {
						animation: auctioneerpulse1 1s linear infinite;
						@keyframes auctioneerpulse1 {
							0% {
								left: -12rem;
								bottom: -12rem;
							}
							50% {
								left: -4rem;
								bottom: -4rem;
							}
							100% {
								left: -12rem;
								bottom: -12rem;
							}
						}
					}
				}
			}

			&.two {
				bottom: -2.6rem;
				right: -2.6rem;

				.highlighter__inner {
					left: -12rem;
					top: -12rem;

					&::before {
						content: '';
						position: absolute;
						height: 16rem;
						width: 16rem;
						background-color: white;
						border-radius: 100%;
						left: -8rem;
						top: -8rem;
					}

					&.pulse {
						animation: auctioneerpulse2 1s linear infinite;
						@keyframes auctioneerpulse2 {
							0% {
								top: -12rem;
								left: -12rem;
							}
							50% {
								top: -4rem;
								left: -4rem;
							}
							100% {
								top: -12rem;
								left: -12rem;
							}
						}
					}
				}
			}

			&.three {
				bottom: -2.6rem;
				left: -2.6rem;

				.highlighter__inner {
					right: -12rem;
					top: -12rem;

					&::before {
						content: '';
						position: absolute;
						height: 16rem;
						width: 16rem;
						background-color: white;
						border-radius: 100%;
						top: -8rem;
						right: -8rem;
					}

					&.pulse {
						animation: auctioneerpulse3 1s linear infinite;
						@keyframes auctioneerpulse3 {
							0% {
								top: -12rem;
								right: -12rem;
							}
							50% {
								top: -4rem;
								right: -4rem;
							}
							100% {
								top: -12rem;
								right: -12rem;
							}
						}
					}
				}
			}

			&.four {
				top: -2.6rem;
				left: -2.6rem;

				.highlighter__inner {
					right: -12rem;
					bottom: -12rem;

					&::before {
						content: '';
						position: absolute;
						height: 16rem;
						width: 16rem;
						background-color: white;
						border-radius: 100%;
						bottom: -8rem;
						right: -8rem;
					}

					&.pulse {
						animation: auctioneerpulse4 1s linear infinite;
						@keyframes auctioneerpulse4 {
							0% {
								bottom: -12rem;
								right: -12rem;
							}
							50% {
								bottom: -4rem;
								right: -4rem;
							}
							100% {
								bottom: -12rem;
								right: -12rem;
							}
						}
					}
				}
			}
		}

		.highlighter__inner {
			height: 16rem;
			width: 16rem;
		}
	}

	.bidselector__streambid__container {
		height: 12rem;
		width: 12rem;
		border: solid 5px var(--accent-color-2);
	}

	.bidselector__streambid__title,
	.bidselector__floor__container__icon {
		font-size: 4rem !important;
	}
}

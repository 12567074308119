.container {
	height: 3.4rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
}

.input {
	text-align: center;
	width: 30%;
	font-size: 1.4rem;
	height: 2.5rem;
	border-radius: 8px;
	border: solid 1px var(--secondary-color-dark);
}

.action {
	height: 90%;
	width: 30%;
	border: none;
	border-radius: 32px;
	border: solid 1px var(--primary-color-dark);
	background: var(--primary-color-light);
	color: var(--primary-color-dark);
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
}

.outer {
	width: 100%;
	height: 100%;
}

.tableWrapper {
	width: 100%;
	height: 85%;
	padding-bottom: 1rem;
	position: relative;
	margin-top: 2rem;
	border-radius: 12px;
	border: 1px solid #d7dceb;
	overflow: auto;
}

.table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	border-style: hidden;
	font-weight: normal;

	thead {
		tr {
			height: 3rem;
			text-align: left;
		}
	}

	th {
		cursor: pointer;
	}

	.icon {
		margin-left: 1rem;
	}

	tr {
		height: 4rem;
	}

	th,
	td {
		padding: 0.5rem 1rem;
	}
}

.sortIcon {
	margin-left: 0.5rem;
}

.row {
	background-color: var(--secondary-color-light);

	&:hover {
		background-color: #ccd9ff;
	}
}

.wishlist {
	position: relative;
	height: 100%;
	width: 100%;
	padding: 1rem;
	background: var(--primary-color-light);
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 10%;
}

.title {
	// position: relative;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 50%;
	width: 100%;

	.explore {
		font-size: var(--large-24-font-size);
	}

	.spacer {
		width: 23%;
		border-bottom: 2px solid var(--secondary-color-dark);
	}

	.closebutton {
		position: absolute;
		top: 2px;
		right: 2px;
		height: 3rem;
		width: 3rem;
		border-radius: 100%;
		border: none;
		font-size: 1.5rem;
		background: var(--primary-color-dark);
		color: var(--primary-color-light);

		&:hover {
			transform: scale(1.05);
		}
	}
}

.nav {
	height: 5%;
	width: 100%;
}

.lots {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80%;
	width: 100%;
	padding-top: 1rem;
}

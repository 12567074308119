.layout {
	height: 100%;
	min-width: 720px;
	display: flex;
	position: relative;
}

.sidebarwrapper {
	height: 100%;
	min-width: 120px;
}

@import 'styles/variables';

.bidhistory {
	width: 48%;
	height: 100%;
	padding: 1%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		height: 10%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-weight: 700;
			text-transform: capitalize;
		}

		.connected {
			font-weight: 400;

			&__icon {
				margin-right: 0.5rem;
			}
		}
	}

	&__currentbid {
		width: 100%;
		height: 10%;
		border: 1px solid;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		font-weight: 700;
	}

	&__estimated {
		width: 100%;
		height: 10%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		font-weight: 500;
	}

	.bids {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 65%;
		width: 100%;
		border-radius: 12px;
		padding-top: 5%;
		background: var(--primary-color-light);
		overflow-y: scroll;
		// overflow-x: hidden;
		padding-right: 8px;
		transition-duration: 0s;
		scrollbar-color: var(--secondary-color-dark) var(--background-color) !important;
		scrollbar-width: thin;

		// &:hover {
		//     overflow-y: scroll;
		//     padding-right: 0px;
		//     transition-duration: 0s;
		// }
	}
}

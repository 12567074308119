.askingPrice {
	height: 12%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 12px;
	position: relative;
	padding: 2%;
	background: var(--secondary-color-light);
}

.sbActive {
	background: var(--accent-color-2);
}

.locked {
	background: var(--warning-color);
	color: var(--primary-color-light);
}

.description {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.title {
	font-size: var(--large-20-font-size);
	font-weight: 700;
	text-transform: capitalize;
}

.subtitle {
	font-size: 0.9rem;
	font-weight: 400;
}

.currencySymbol {
	width: 35%;
	text-align: right;
	font-size: 2rem;
}

.inputContainer {
	height: 100%;
	width: 40%;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--primary-color-light);

	&:focus-within {
		border: solid 3px var(--accent-color-1);
	}

	&.success {
		background: var(--admin-accent-1);
		color: var(--primary-color-light);
	}

	&.error {
		background: var(--warning-color);
		color: var(--primary-color-light);
	}
}

.input {
	width: 80%;
	height: 100%;
	text-align: right;
	border: none;
	font-weight: 700;
	letter-spacing: 0.004vw;
	font-size: var(--extra-large-34-font-size);
	color: var(--accent-color-1);

	&:focus {
		outline: none;
	}

	&.success {
		background: var(--admin-accent-1);
		color: var(--primary-color-light);
	}

	&.error {
		background: var(--warning-color);
		color: var(--primary-color-light);
	}
}

.undo {
	background: var(--primary-color-light);
	border: none;
	font-size: 1.2rem;
}

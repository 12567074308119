.searchpanel {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 1rem;
}

.title {
	width: 100%;
	height: 2rem;
	font-size: var(--large-24-font-size);
	margin-top: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.undo {
	background: var(--primary-color-light);
	border: solid 1px var(--secondary-color-dark);
	padding: 0.5rem 1rem;
	font-size: 1rem;
	border-radius: 8px;

	&:hover {
		background: var(--secondary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.input {
	font-size: var(--regular-16-font-size);
	height: 2.5rem;
	width: 100%;
	padding-left: 0.8rem;
	border-radius: 8px;
	border: solid 1px var(--secondary-color-dark);
	outline: none;
}

.category {
	width: 100%;
	height: 2.5rem;
	display: flex;
	align-items: center;
	font-weight: 400;

	input {
		width: 1.2rem;
		height: 1.2rem;
		accent-color: var(--primary-color-dark);
		color: var(--primary-color-light);
		margin-right: 1rem;
	}
}

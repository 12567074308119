.catalogue__controls {
	height: 5%;
	width: 100%;
	display: flex;
	padding: 0 1rem;
	padding-bottom: 1rem;
	align-items: center;
	justify-content: space-between;

	.filters {
		button {
			padding: 0.5rem 1rem;
			background: var(--primary-color-light);
			border: none;
			border-radius: 8px;

			&:hover:not(.active) {
				background: var(--secondary-color-dark);
			}

			&.active {
				background: var(--primary-color-dark);
				color: var(--primary-color-light);
			}
		}
	}

	.pagination {
		select {
			padding: 0.5rem 1rem;
			border: solid 1px var(--secondary-color-dark);
			border-radius: 8px;
			margin-right: 1rem;
		}

		button {
			background: var(--primary-color-light);
			border: none;
			height: 2rem;
			width: 2rem;
			border-radius: 8px;

			&:hover {
				background: var(--primary-color-dark);
				color: var(--primary-color-light);
			}
		}
	}

	.itemcontrols {
		display: flex;

		button {
			padding: 0.5rem 1rem;
			border-radius: 12px;
			border: solid 1px var(--secondary-color-dark);
			background: var(--primary-color-light);
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 1rem;
			text-transform: capitalize;

			&:hover {
				color: var(--primary-color-light);
				background: var(--primary-color-dark);
			}
		}

		:nth-child(1) {
			.icon {
				margin-right: 0.5rem;
			}
		}

		:nth-child(2) {
			.icon {
				margin-left: 0.5rem;
			}
		}
	}
}

.flipdater__container {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
}

.flipdater__newvalue {
	position: absolute;
	width: 100%;
	height: 100%;
	top: -100%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&.left {
		justify-content: flex-start;
	}

	&.right {
		justify-content: flex-end;
	}

	&.animate-flip {
		animation: flipnewvalue 0.3s linear;
		@keyframes flipnewvalue {
			50% {
				top: -50%;
			}
			100% {
				top: 0%;
			}
		}
	}
}

.flipdater__currentvalue {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0%;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&.left {
		justify-content: flex-start;
	}

	&.right {
		justify-content: flex-end;
	}

	&.animate-flip {
		animation: flipcurrentvalue 0.3s linear;
		@keyframes flipcurrentvalue {
			50% {
				top: 50%;
			}
			100% {
				top: 100%;
			}
		}
	}
}

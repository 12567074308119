.details {
	height: 100%;
	width: 25%;
	border: solid 1px var(--secondary-color-dark);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	overflow: auto;
}

.row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: capitalize;
	font-size: 1.3rem;

	@media (max-width: 1600px) {
		font-size: 1rem;
	}
}

.title {
	font-size: 1.5rem;
	@media (max-width: 1600px) {
		font-size: 1.3rem;
	}
}

.accent1 {
	color: var(--accent-color-1);
}

.winningColor {
	color: var(--wonitem-highlight);
}

.warningColor {
	color: var(--warning-color);
}

.underline {
	text-decoration: underline;
}

.largeFont {
	font-size: var(--large-24-font-size);
}

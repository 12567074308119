.mobileactionbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 8%;
	padding: 0 0.5rem;
}

.mobileactionbar__toggle {
	height: 60%;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 1rem;
	border: none;
	border-radius: 24px;

	.toggle__item {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		border-radius: 24px;
	}
}

.mobileactionbar__info {
	height: 90%;
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;

	.mobileactionbar__current {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.mobileactionbar__live {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.1rem 0.4rem;
			border-radius: 4px;

			.icon {
				margin-right: 0.2rem;
				font-size: 0.7rem;
			}
		}

		.mobileactionbar__askingprice {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.mobileactionbar__nextlot {
	height: 100%;
	width: 20%;
	position: relative;
	padding: 0.2rem 0;

	display: flex;
	align-items: center;
	justify-content: center;

	&.finish .icon {
		height: 50%;
		width: 50%;
	}

	.nextlot__lotnumber {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0.1rem 0.3rem;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0.8;
	}

	.nextlot__image {
		max-height: 100%;
		max-width: 100%;
	}
}

.container {
	height: 100%;
	width: 16%;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: var(--secondary-color-light);
}

.image {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 100%;
		max-width: 100%;
	}
}

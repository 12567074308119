.holdingpage {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2%;
    font-family: 'Roboto', sans-serif;
}

.holdingpage__auctionhouse {
    height: 8%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.holdingpage__video {
    height: 50%;
    width: 40%;
    margin-top: 1%;
}

.holdingpage__title,
.holdingpage__body,
.holdingpage__footer {
    height: 10%;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.holdingpage__title {
    font-size: 19pt;
    font-weight: 500;
}

.holdingpage__body {
    font-size: 13pt;
    font-weight: 300;
}

.holdingpage__footer {
    font-size: 14pt;
    font-weight: 500;
}
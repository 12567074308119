.container {
	height: 3vh;
	position: absolute;
	border-radius: 0.5vh;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 55%;
	text-transform: uppercase;
	font-weight: 400;
}

.currentItem {
	background: var(--accent-color-1);
}

.autoBid {
	background: var(--primary-color-dark);
}

.wishlist {
	background: var(--wishlist-color);
}

.text {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary-color-light);
	font-size: var(--small-12-font-size);
}

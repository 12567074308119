@import "styles/variables";

.biddermobile__mainimage {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 2% 0;
    position: relative;

    .biddermobile__slickarrow {
        // display: none !important;
        position: absolute;
        top: 0;
        height: 100%;
        width: 10%;
        // background-color: aquamarine;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .icon {
            background-color: black;
            border-radius: 12px;
            // color: $secondary-color-light;
            height: 1.5rem;
            width: 1.5rem;
            opacity: 0.5;
            padding: 0.2rem;
        }

        &.next {
            right: 0;
        }

        &.prev {
            left: 0;
        }
    }


    .slick-list, .slick-track, .slick-slide {
        height: 100%;
    
        > div {
            height: 100%;
        }
    }
    
    .slick-list .slick-track .slick-slide {
        float: left;
    }

    &__slide {
        height: 100%;
        width: 100%;
    }

    &__inner {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: 100%;
            width: auto;
            margin: auto;
        }
    }

    &__pagination {
        position: absolute;
        bottom: 1vh;
        right: 1vw;
        padding: 4px 8px;
    
        border-radius: 8px;
        // background-color: $primary-color-dark;
        opacity: 0.6;
        // color: white;
        font-size: 0.9rem;
    }
}
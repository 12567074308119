.adminpages__sidebar {
	height: 100%;
	width: 10%;
	min-width: 125px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 0.5rem;
	overflow: hidden;

	&__title {
		margin-top: 2.5rem;
		// font-size: 1.2rem;
	}

	&__buttons {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		&__1,
		&__2 {
			display: flex;
			flex-direction: column;

			&__button {
				margin-bottom: 2rem;
				height: 3rem;
				width: 100%;
				// font-size: 1rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				// color: white;
				// background-color: #20273D;
				border: none;
				text-transform: capitalize;

				&-icon-wrapper {
					width: 30%;
					display: flex;
					align-items: center;
				}

				span {
					width: 70%;
					padding-left: 1rem;
					display: flex;
					align-items: flex-start;
					text-align: left;
				}

				&-icon {
					margin-inline: auto;
					height: 2rem;
					// font-size: 2rem;
				}

				&:hover:not(.username) span,
				&.active span {
					transform: translate(5%, 0);
				}

				&.activated {
					transform: translate(10%, 0);
				}

				&.disabled {
					opacity: 0.3;
					cursor: default;
				}

				&.username {
					cursor: default;
				}
			}
		}

		&__2 {
			border-top: 1px solid;
			padding-top: 2rem;
		}
	}
}

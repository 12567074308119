.container {
	padding: 0 4rem;
	height: 8%;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 20px;
	margin: 3rem 0;
	font-size: 2vmin;
}

.bgColor {
	background: var(--background-color);
}

.card {
	height: 100%;
	border-radius: 24px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	overflow: hidden;
}

.icon {
	grid-area: 1 / 1 / 3 / 2;
	display: flex;
	align-items: center;
	justify-content: center;

	> * {
		height: 2.5vmin;
		width: 2.5vmin;
		color: var(--accent-color-1);
		border: solid 1px var(--accent-color-1);
		border-radius: 100%;
		padding: 0.5rem;
	}
}

.title {
	grid-area: 1 / 2 / 2 / 4;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}

.value {
	grid-area: 2 / 2 / 3 / 4;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	font-weight: 400;
}

@import 'styles/variables';

.addauction__review {
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 1rem;

	.slider-section {
		&.hidden {
			opacity: 0;
			height: 0;
			transform: translate(0, 100%) scale(0, 0);
		}
	}

	&__row-1 {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;

		align-items: center;
		padding: 1rem 2rem;
		border-bottom: #d2d2d2 1px solid;

		&__p-bar {
			display: block;
			background: darkseagreen;
			height: 15px;
			width: 60%;
			border-radius: 1rem;
		}

		&__action {
			height: 3rem;
			width: 10rem;
			border: none;
			border-radius: 24px;
			text-transform: capitalize;
			font-size: 1rem;

			&-icon {
				margin-left: 1rem;
			}

			&.disabled {
				background-color: #8e8e8e;
				cursor: default;
			}
		}
	}

	&__row-2 {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
		font-weight: normal;

		input {
			// margin: 1rem 0.3rem;
			margin-left: 1rem;
			padding: 0.5rem 0.7rem;
			border-radius: 0.5rem;
			border: 1px solid #c6c6c6;
		}

		.round-button {
			margin-left: 1rem;
			padding: 0.3rem 0.5rem;
			border-radius: 0.5rem;
			border: 1px solid #c6c6c6;
			text-align: center;
			cursor: pointer;
		}

		&__left-panel {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// width: 65%;

			&__page-toggle {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			&__filter {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	&__row-3 {
		& .lazy-img-container-thumbnail {
			width: 5rem;
			height: 5rem;

			> img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		& table {
			border-collapse: collapse;
			width: 100%;
		}

		& tbody {
			min-height: 60rem;
		}

		& th {
			background: #f0f0ff;
			padding: 0.5rem 0.5rem;
		}

		& td {
			padding: 0.5rem 0.5rem;
			font-weight: normal;
			border-bottom: #eeeeee solid 1px;
		}

		& td:nth-child(9n-7) {
			font-weight: bold;
		}

		& td:nth-child(9n-5) {
			padding: 1rem 0.5rem;
		}

		// & td:nth-child(9n-1) {
		//     color: #3D6EFF;
		//     text-align: right;
		//     & span {
		//         cursor: pointer;
		//         border-bottom: white solid 1px;
		//     }
		//     & span:hover {
		//         border-bottom: #3D6EFF solid 1px;
		//     }
		// }

		& thead,
		& tfoot {
			color: #333;
			text-align: left;
		}

		&__left-panel {
			width: 40%;
			float: left;

			& .img-large-display {
				height: 40vh;
			}

			& .img-thumbnails {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				padding: 1rem;
				margin-top: 1rem;
				overflow-y: auto;

				&:hover {
					cursor: pointer;
				}
			}

			& .lazy-img-container {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;

				& img {
					display: block;
					margin-inline: auto;
					max-width: 100%;
					max-height: 35vh;
				}
			}
		}

		&__right-panel {
			width: 55%;
			float: right;
			display: flex;
			flex-wrap: wrap;

			& .row {
				display: flex;
				justify-content: space-between;
				width: 100%;
				margin-top: 1rem;

				& .input-section {
					margin-inline: 1rem;
					flex: 1;
				}

				& .input-section.error {
					color: #b40000;

					& p {
						opacity: 1;
					}

					& input {
						transform: translate(0, 0.6rem);
						color: red;
						border: solid 1px #b40000;
						background: #ffd9d9;
					}
				}

				& .input-section.success {
					color: #005700;

					& p {
						opacity: 1;
					}

					& input {
						transform: translate(0, 0.6rem);
						color: #006500;
						border: solid 1px #005700;
						background: #f5fff2;
					}
				}
			}

			& .subtitle {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				width: 100%;
				font-weight: 400;
			}

			& .label {
				margin-bottom: 0.1rem;
				font-weight: 400;
			}

			& .info {
				height: 0;
				opacity: 0;
				margin-bottom: 0.5rem;
				font-weight: normal;
			}

			& textarea,
			& input {
				height: 2.5rem;
				width: 100%;
				border: solid 1px #d7dceb;
				border-radius: 8px;
				margin-bottom: 1rem;
				margin-right: 5rem;
				padding-inline: 1rem;
			}

			.lot-number {
				flex-shrink: 2;
			}

			.description {
				textarea {
					height: 10vh;
					resize: none;
					padding: 1vh;
				}
			}
		}
	}
}

.addauction__uploadprogress {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.addauction__uploadprogress-row1 {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.addauction__uploadprogress-row2 {
	margin-top: 1rem;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;

	table {
		width: 50%;
		text-align: center;
	}

	table thead {
		background: #f0f0ff;
		padding: 0.5rem 0.5rem;
	}

	table td {
		border-bottom: #eeeeee solid 1px;
	}

	table tbody tr td {
		font-weight: 400;
		border-bottom: #eeeeee solid 1px;
	}
}

.addauction__uploadprogress__title {
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.addauction__uploadprogress__subtitle {
	font-weight: normal;
}

.addauction__uploadprogress__bar__outer {
	width: 60%;
	border: solid 1px;
	height: 1rem;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
}

.addauction__uploadprogress__bar__inner {
	height: 100%;
	width: 20%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: darkseagreen;
	border-radius: 12px;
	animation: loader 2s linear infinite;

	&.complete {
		width: 100%;
		animation: none;
	}
}

@keyframes loader {
	0% {
		left: -20%;
	}

	// 50% {
	//     left: 50%;
	// }

	100% {
		left: 100%;
	}
}

.lazy-img-container-review {
	height: 5rem;
	width: 5rem;

	> img {
		max-width: 100%;
		max-height: 100%;
	}
}

.upload__icon {
	font-size: 1.2rem;
	margin-right: 1rem;
}

.upload__icon.check {
	color: darkseagreen;
}

.review__table__row {
	cursor: pointer;

	&.withdrawn {
		opacity: 0.7;
	}

	&:hover {
		background-color: #ccd9ff;
	}

	p.error {
		color: var(--warning-color);
		height: 100%;
		font-size: 1.3rem;
	}
}

.review__withdraw__input {
	height: 1.2rem;
	width: 1.2rem;
}

.addauction__review__row-2__action-icon {
	margin-right: 1rem;
}

.review__field {
	width: 50%;
	margin-bottom: 3rem;

	&.full {
		width: 100%;
	}
}

.review__field__title {
	font-size: 1.2rem;
	margin-bottom: 1rem;
}

.uploadicon {
	margin-left: 1rem;

	&.check {
		color: darkseagreen;
	}
}

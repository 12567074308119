:root {
	--background-color: #f5f6f7;
	--primary-color-light: #ffffff;
	--primary-color-dark: #20273d;
	--primary-color-dark-offset: #5d6275;
	--secondary-color: #ccd9ff;
	--secondary-color-light: #edf0f7;
	--secondary-color-dark: #d7dceb;
	--accent-color-1: #345fdb;
	--accent-offset-1: #2c50b8;
	--accent-offset-2: #e6eeff;
	--accent-color-2: #5cffb8;
	--warning-color: #eb5e6c;
	--warning-color-offset: #9f4049;
	--warning-color-mid: #ffadac;
	--warning-color-light: #ffccd1;
	--warning-color-light-offset: #ffccd18b;
	--wishlist-color: #eb5e6c;
	--wishlist-color-offset: #9b3f49;
	--lost-color: #747e99;
	--wonitem-highlight: #13949d;
	--catalogue-secondary: #edeff2;
	--admin-secondary-light: #bbbfcc;
	--admin-accent-1: #3ab99c;
	--admin-accent-offset-1: #267c68;
	--admin-accent-2: #f1b44c;
	--admin-accent-offset-2: #a57c36;
	--small-12-font-size: 0.67rem;
	--small-14-font-size: 0.73rem;
	--regular-16-font-size: 0.83rem;
	--regular-18-font-size: 0.9465rem;
	--large-20-font-size: 1.02rem;
	--large-24-font-size: 1.25rem;
	--extra-large-34-font-size: 1.77rem;
}

.background-color {
	background-color: var(--background-color);
}
.border-2-background-color {
	border: 2px solid var(--background-color);
}
.border-top-2-background-color {
	border-top: 2px solid var(--background-color);
}

.primary-color-light {
	color: var(--primary-color-light);
}
.bg-primary-color-light {
	background-color: var(--primary-color-light);
}

.primary-color-dark {
	color: var(--primary-color-dark);
}
.bg-primary-color-dark {
	background-color: var(--primary-color-dark);
}
.border-primary-color-dark {
	border: solid 1px var(--primary-color-dark);
}
.bg-primary-color-dark-offset {
	background-color: var(--primary-color-dark-offset);
}

.secondary-color {
	color: var(--secondary-color);
}
.bg-secondary-color {
	background-color: var(--secondary-color);
}

.secondary-color-light {
	color: var(--secondary-color-light);
}
.bg-secondary-color-light {
	background-color: var(--secondary-color-light);
}

.secondary-color-dark {
	color: var(--secondary-color-dark);
}
.bg-secondary-color-dark {
	background-color: var(--secondary-color-dark);
}
.border-secondary-color-dark {
	border: solid 1px var(--secondary-color-dark);
}
.border-top-secondary-color-dark {
	border-top: solid 1px var(--secondary-color-dark);
}
.border-right-secondary-color-dark {
	border-right: solid 1px var(--secondary-color-dark);
}
.border-bottom-secondary-color-dark {
	border-bottom: solid 1px var(--secondary-color-dark);
}
.hover-border-secondary-color-dark:hover {
	border: solid 1px var(--secondary-color-dark);
}
.border-left-secondary-color-dark {
	border-left: solid 1px var(--secondary-color-dark);
}
.border-2-secondary-color-dark {
	border: solid 2px var(--secondary-color-dark);
}
.hover-bg-secondary-color-dark:hover {
	background-color: var(--secondary-color-dark);
}

.accent-color-1 {
	color: var(--accent-color-1);
}
.bg-accent-color-1 {
	background-color: var(--accent-color-1);
}
.border-accent-color-1 {
	border: solid 1px var(--accent-color-1);
}
.border-2-accent-color-1 {
	border: solid 2px var(--accent-color-1);
}
.focus-outline-accent-color-1:focus-within {
	border: solid 3px var(--accent-color-1);
}

.bg-accent-offset-1 {
	background-color: var(--accent-offset-1);
}
.hover-bg-accent-offset-1:hover {
	background-color: var(--accent-offset-1);
}
.bg-accent-offset-2 {
	background-color: var(--accent-offset-2);
}

.accent-color-2 {
	color: var(--accent-color-2);
}
.bg-accent-color-2 {
	background-color: var(--accent-color-2);
}
.border-2-accent-color-2 {
	border: solid 2px var(--accent-color-2);
}

.warning-color {
	color: var(--warning-color);
}
.bg-warning-color {
	background-color: var(--warning-color);
}
.hover-bg-warning-color-offset:hover {
	background-color: var(--warning-color-offset);
}
.border-warning-color {
	border: solid 1px var(--warning-color);
}

.warning-color-mid {
	color: var(--warning-color-mid);
}
.bg-warning-color-mid {
	background-color: var(--warning-color-mid);
}
.border-warning-color-mid {
	border: solid 1px var(--warning-color-mid);
}

.warning-color-light {
	color: var(--warning-color-light);
}
.bg-warning-color-light {
	background-color: var(--warning-color-light);
}
.hover-bg-warning-color-light-offset:hover {
	background-color: var(--warning-color-light-offset);
}

.wishlist-color {
	color: var(--wishlist-color);
}
.bg-wishlist-color {
	background-color: var(--wishlist-color);
}
.hover-bg-wishlist-color-offset:hover {
	background-color: var(--wishlist-color-offset);
}
.border-inset-wishlist {
	box-shadow: inset 0px 0px 0px 1px var(--wishlist-color);
}

.lost-color {
	color: var(--lost-color);
}
.bg-lost-color {
	background-color: var(--lost-color);
}

.wonitem-highlight {
	color: var(--wonitem-highlight);
}
.bg-wonitem-highlight {
	background-color: var(--wonitem-highlight);
}

.catalogue-secondary {
	color: var(--catalogue-secondary);
}
.bg-catalogue-secondary {
	background-color: var(--catalogue-secondary);
}

.admin-secondary-light {
	color: var(--admin-secondary-light);
}
.bg-admin-secondary-light {
	background-color: var(--admin-secondary-light);
}

.admin-accent-1 {
	color: var(--admin-accent-1);
}
.bg-admin-accent-1 {
	background-color: var(--admin-accent-1);
}
.hover-bg-admin-accent-offset-1:hover {
	background-color: var(--admin-accent-offset-1);
}

.admin-accent-2 {
	color: var(--admin-accent-2);
}
.bg-admin-accent-2 {
	background-color: var(--admin-accent-2);
}
.hover-bg-admin-accent-2:hover {
	background-color: var(--admin-accent-2);
}

/* ::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
	background: rgb(240, 231, 231);
	box-shadow: none;
}

::-webkit-scrollbar-thumb {
	background: lightgray;
	border: solid 1px gray;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #5d6275;
} */

.small-12-font-size {
	font-size: 0.67rem;
}
.small-14-font-size {
	font-size: 0.73rem;
}
.regular-16-font-size {
	font-size: 0.83rem;
}
.regular-18-font-size {
	font-size: 0.9465rem;
}
.large-20-font-size {
	font-size: 1.02rem;
}
.large-24-font-size {
	font-size: 1.25rem;
}
.extra-large-34-font-size {
	font-size: 1.77rem;
}

.border-none {
	border: none;
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
	.app-bidder .small-12-font-size {
		font-size: 0.57rem !important;
	}
	.app-bidder .small-14-font-size {
		font-size: 0.63rem !important;
	}
	.app-bidder .regular-16-font-size {
		font-size: 0.73rem !important;
	}
	.app-bidder .regular-18-font-size {
		font-size: 0.8465rem !important;
	}
	.app-bidder .large-20-font-size {
		font-size: 0.92rem !important;
	}
	.app-bidder .large-24-font-size {
		font-size: 1.15rem !important;
	}
	.app-bidder .extra-large-34-font-size {
		font-size: 1.67rem !important;
	}

	:root {
		--small-12-font-size: 0.57rem;
		--small-14-font-size: 0.63rem;
		--regular-16-font-size: 0.73rem;
		--regular-18-font-size: 0.8465rem;
		--large-20-font-size: 0.92rem;
		--large-24-font-size: 1.15rem;
		--extra-large-34-font-size: 1.67rem;
	}
}

@media only screen and (max-width: 1200px) {
	.app-clerk .small-12-font-size,
	.app-viewer .small-12-font-size {
		font-size: 0.57rem !important;
	}

	.app-clerk .small-14-font-size,
	.app-viewer .small-14-font-size {
		font-size: 0.63rem !important;
	}

	.app-clerk .regular-16-font-size,
	.app-viewer .regular-16-font-size {
		font-size: 0.73rem !important;
	}

	.app-clerk .regular-18-font-size,
	.app-viewer .regular-18-font-size {
		font-size: 0.8465rem !important;
	}

	.app-clerk .large-20-font-size,
	.app-viewer .large-20-font-size {
		font-size: 0.92rem !important;
	}

	.app-clerk .large-24-font-size,
	.app-viewer .large-24-font-size {
		font-size: 1.15rem !important;
	}

	.app-clerk .extra-large-34-font-size,
	.app-viewer .extra-large-34-font-size {
		font-size: 1.67rem !important;
	}

	:root {
		--small-12-font-size: 0.57rem;
		--small-14-font-size: 0.63rem;
		--regular-16-font-size: 0.73rem;
		--regular-18-font-size: 0.8465rem;
		--large-20-font-size: 0.92rem;
		--large-24-font-size: 1.15rem;
		--extra-large-34-font-size: 1.67rem;
	}
}

@media only screen and (min-width: 1500px) {
	.small-12-font-size {
		font-size: 0.77rem !important;
	}
	.small-14-font-size {
		font-size: 0.83rem !important;
	}
	.regular-16-font-size {
		font-size: 0.93rem !important;
	}
	.regular-18-font-size {
		font-size: 1.0465rem !important;
	}
	.large-20-font-size {
		font-size: 1.12rem !important;
	}
	.large-24-font-size {
		font-size: 1.35rem !important;
	}
	.extra-large-34-font-size {
		font-size: 1.87rem !important;
	}

	:root {
		--small-12-font-size: 0.77rem;
		--small-14-font-size: 0.83rem;
		--regular-16-font-size: 0.93rem;
		--regular-18-font-size: 1.0465rem;
		--large-20-font-size: 1.12rem;
		--large-24-font-size: 1.35rem;
		--extra-large-34-font-size: 1.87rem;
	}
}

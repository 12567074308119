.webrtc__container {
	#webrtc__player::-webkit-media-controls-timeline {
		display: none;
	}

	position: relative;
	height: 100%;
	width: 100%;

	.webrtc__overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: black;
		opacity: 0.5;
		cursor: pointer;
		z-index: 2;

		.webrtc__overlay-icon {
			font-size: 2rem;
			color: white;
		}
	}

	#webrtc__player {
		height: 100%;
		width: 100%;
	}
}

.mobilemainpanel {
	#webrtc__player {
		width: auto;
		height: 100%;
	}
}

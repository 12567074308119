.auctionlist {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;

	&__loading {
		font-size: 1.5rem;
		opacity: 0.8;
	}

	&__header {
		margin-bottom: 1rem;
		text-transform: capitalize;
	}

	&__row__1 {
		width: 100%;
		height: 5%;
	}

	&__row__2 {
		width: 100%;
		height: 10%;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__nav {
		display: flex;
		align-items: center;
	}

	&__pagination {
		margin-right: 1rem;
		font-weight: 400;
	}

	&__nav__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		width: 2rem;
		border-radius: 100%;
		border: none;
		margin-right: 1rem;
	}

	&__range {
		display: flex;
		align-items: center;
		padding: 0.5rem 1rem;
		font-weight: 400;
	}

	&__range__icon {
		margin-left: 1rem;
	}

	&__archive {
		margin-left: 1rem;

		input {
			margin-left: 0.5rem;
			height: 1rem;
			width: 1rem;
		}
		font-weight: 400;
	}

	&__search {
		display: flex;
	}

	&__input {
		border: none;
		border-radius: 8px;
		padding: 0.5rem 1rem;
		margin-right: 1rem;
	}

	&__input__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		width: 2rem;
		border-radius: 100%;
		border: none;
		margin-right: 1rem;
	}

	&__row__3 {
		width: 100%;
		height: 85%;
		padding-bottom: 1rem;
		position: relative;
		margin-top: 2rem;
	}

	.table__wrapper {
		width: 100%;
		height: 100%;
		border-radius: 12px;
		border: 1px solid #d7dceb;
		overflow: auto;
	}

	&__table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		border-style: hidden;
		font-weight: normal;

		thead {
			tr {
				height: 3rem;
				text-align: left;
			}
		}

		th {
			cursor: pointer;
		}

		.icon {
			margin-left: 1rem;
		}

		tr {
			height: 4rem;
		}

		th,
		td {
			padding: 0.5rem 1rem;
		}
	}

	&__table__row {
		cursor: pointer;

		.archive-notification {
			position: fixed;
			top: 50%;
			left: 50%;
			padding: 1rem 2rem;
			background: var(--primary-color-light);
			border: solid 1px var(--admin-accent-2);
		}

		.archive-button {
			border-radius: 8px;
			height: 2rem;
			width: 2rem;
			font-size: 1rem;
			border: solid 1px #ccd9ff;
			margin-left: 1rem;
			// color: var(--primary-color-light);

			.icon {
				margin: unset;
			}

			&.archive {
				margin-right: 3rem;
				background: var(--admin-accent-2);
				&:hover {
					background: var(--admin-accent-offset-2);
				}
			}

			&.delete,
			&.error {
				background: var(--warning-color);
				&:hover {
					background: var(--warning-color-offset);
				}
			}
			&.unarchive,
			&.success {
				background: var(--admin-accent-1);
				&:hover {
					background: var(--admin-accent-offset-1);
				}
			}
		}

		.auctionlist__settings {
			border-radius: 8px;
			padding: 0.3rem;
			font-size: 1rem;
			border: solid 1px #ccd9ff;
			background: white;
			margin-left: 1rem;

			&:hover {
				background: var(--secondary-color-dark);
			}

			.icon {
				margin: unset;
			}
		}
	}

	&__table__row:hover {
		background-color: #ccd9ff;
	}

	&__row__controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__row__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__row__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		width: 2rem;
		border-radius: 100%;
		margin-right: 1rem;
	}
}

.container {
	width: 100%;
	height: 3.4rem;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.action {
	height: 90%;
	width: 95%;
	border: none;
	border-radius: 32px;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 1.2rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
}

.half {
	height: 90%;
	width: 45%;
	border: none;
	border-radius: 32px;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 1.2rem;
	font-weight: 600;
	text-transform: uppercase;
}

.confirming {
	position: relative;
	height: 90%;
	width: 95%;
	border: none;
	border-radius: 32px;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;

	.title {
		font-size: 1.2rem;
		z-index: 2;
		font-weight: 600;
	}

	.slider {
		position: absolute;
		left: 0;
		height: 100%;
		width: 20%;
		border: none;
		border-radius: 32px;
		background: var(--primary-color-dark-offset);
		animation: confirmingslider 5s infinite;

		@keyframes confirmingslider {
			0% {
				width: 20%;
			}

			50% {
				width: 100%;
			}

			0% {
				width: 20%;
			}
		}
	}
}

@import 'styles/variables';

.bidderinterface {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	// overflow: hidden;

	.shadow {
		width: 85%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 15%;
		opacity: 0.5;
		background-color: black;
		z-index: 6;
	}

	&__sidepanel {
		width: 45%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: 5;
		background-color: var(--primary-color-light);
		position: absolute;
		left: -100%;

		&__container {
			background-color: white;
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 7;
			background-color: rgba(255, 255, 255, 0);
			overflow-y: hidden;
		}
	}

	&__container {
		margin-left: 5%;
		width: 95%;
		max-width: 1920px;
		height: 100%;

		&.viewermode {
			margin: 0;
		}

		&__elastic {
			display: block;
			margin-inline: auto;
			width: 95%;
			height: 98%;
		}

		&__row-1 {
			position: relative;
			width: 100%;
			height: 64%;
			margin-top: 1rem;
			display: flex;
			justify-content: space-between;
			overflow: hidden;

			&__mainpanel {
				height: 100%;
				width: 33%;
				z-index: 2;

				&.layout_1 {
					width: 60%;
					left: 48%;
				}

				&.layout_2 {
					width: 100%;
					left: 67%;
				}
			}
		}

		&__row-2 {
			width: 100%;
			height: 28.33%;
			margin-top: 1.5rem;
		}
	}
}

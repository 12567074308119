.container {
	display: flex;
	width: 100%;
	height: 3.2rem;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	overflow: hidden;
}

.spacer {
	flex-grow: 2;
	height: 1px;
	background-color: var(--secondary-color-dark);
}

.text {
	height: 3.21vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	flex-grow: 1;
	font-size: var(--small-14-font-size);
}

.open {
	height: 90%;
	width: 98%;
	border: solid 1px var(--primary-color-dark);
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--primary-color-dark);

	.icon {
		font-size: 1.5rem;
		margin-right: 1rem;
	}
}

.closed {
	height: 60%;
	width: 90%;
	background: var(--secondary-color-light);
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	.icon {
		font-size: 1.2rem;
	}
}

.nav {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.pagebutton {
	padding: 0.5rem 1rem;
	border-radius: 8px;
	border: solid 1px var(--secondary-color-dark);
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	text-transform: capitalize;
	font-size: var(--regular-18-font-size);

	&:hover:not([disabled]) {
		color: var(--primary-color-light);
		background: var(--primary-color-dark);
	}

	&:active {
		transform: scale(1.05);
	}
}

.autowithdraw {
	width: 100%;
	padding-bottom: 1rem;

	input {
		margin-right: 1rem;
		padding: 0.3rem 0.5rem;
		border-radius: 0.5rem;
		border: 1px solid #c6c6c6;
		text-align: center;
		font-size: 16px;
	}

	button {
		padding: 0.2rem 0.5rem;
		width: 6rem;
		border-radius: 8px;
		border: 1px solid #c6c6c6;
		background: var(--warning-color);
		color: var(--primary-color-light);
		font-size: 16px;
	}
}

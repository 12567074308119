.biddingpanel {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 47%;
	border-radius: 12px;
	margin: 0;
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background: var(--primary-color-light);
	border: solid 1px var(--secondary-color-dark);
}

.manual {
	height: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.askingprice {
	height: 40%;
	width: 95%;
	border-radius: 12px;
	background-color: var(--secondary-color-light);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.askingpricetitle {
	margin-left: 1vw;
	opacity: 70%;
	font-weight: 400;
	letter-spacing: 0.016vw;
	font-size: var(--regular-16-font-size);
}

.askingpricevalue {
	margin-right: 1vw;
	height: 100%;
	width: 50%;
	font-weight: 700;
	letter-spacing: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: var(--large-24-font-size);

	.flipdater__container {
		.flipdater__newvalue,
		.flipdater__currentvalue {
			justify-content: flex-end;
		}
	}
}

// .waiting {
// 	text-align: center;
// 	vertical-align: middle;
// 	font-size: 1rem;
// 	font-weight: 500;
// 	opacity: 70%;
// }

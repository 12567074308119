@import 'styles/variables';

.bids > .message {
	margin-bottom: 1.5vh;
	width: 20.83vw;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.message__spacer {
		flex-grow: 2;
		height: 1px;
		// background-color: $secondary-color-dark;
	}

	.message__text {
		height: 3.21vh;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 0.8rem;
		flex-grow: 1;
		text-transform: capitalize;
	}
}

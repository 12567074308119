@import 'styles/variables';

.login-container {
	height: 100%;
	width: 100%;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	// background-color: $primary-color-dark;

	& .login {
		width: 35%;
		min-width: 600px;
		max-width: 900px;
		// border: solid 1px;
		border-radius: 12px;
		// background-color: white;
		overflow: auto;

		& .content {
			margin: 5% auto;
			height: 80%;
			width: 90%;
		}

		& img {
			width: 100%;
			padding: 10% 5%;
		}

		&__subtitle {
			text-align: center;
		}

		&__group {
			height: 70px;
			display: block;
			& p {
				margin: 5% 0 1% 0;
			}
			& input,
			& select {
				width: 100%;
				height: 80%;
				// font-size: $regular-16-font-size;
				padding: 0 1.3vw;
				border-radius: 12px;
				// border: solid 1px $secondary-color-dark;
			}
		}

		.login__buttons__authorise {
			width: 45%;
			margin-left: 2.5%;
			margin-right: 2.5%;
			height: 5.2vh;
			margin-top: 5vh;
			border-radius: 12px;
			border: none;
			// color: white;
			// background-color: #6C8689;
			cursor: pointer;

			&.admin {
				background-color: var(--admin-accent-2);
			}
		}

		.login__info {
			margin-top: 2vh;

			.login__emptyfieldwarning {
				// color: red;
				font-weight: 600;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.login-container {
		& .login {
			width: 100%;
			height: 100%;
			min-width: 0;
		}
	}
}

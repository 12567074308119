.filters {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button {
	padding: 0.5rem 1rem;
	margin: 0 1rem 0 0;
	min-width: 6rem;
	font-size: var(--regular-16-font-size);
	border-radius: 8px;
	border: solid 1px var(--secondary-color-dark);
	background: var(--primary-color-light);

	&:hover:not(.active) {
		background: var(--secondary-color-light);
	}

	&:active {
		transform: scale(1.05);
	}
}

.active {
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
}

.pagination {
	display: flex;
}

.dropdown {
	font-size: var(--regular-16-font-size);
	margin: 0 0.5rem;
	padding: 0.5rem 1rem;
	border: solid 1px var(--secondary-color-dark);
	border-radius: 8px;
	cursor: pointer;
}

.pagebutton {
	padding: 0.5rem 1rem;
	border-radius: 8px;
	border: solid 1px var(--secondary-color-dark);
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	text-transform: capitalize;
	font-size: var(--regular-16-font-size);

	&:hover:not([disabled]) {
		color: var(--primary-color-light);
		background: var(--primary-color-dark);
	}

	&:active {
		transform: scale(1.05);
	}
}

@import 'styles/variables';

.currentbid {
	height: 12%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 12px;
	position: relative;
	padding: 2%;

	&__description {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__title {
			font-weight: 700;
			text-transform: capitalize;
		}
	}

	&__value {
		height: 100%;
		width: 40%;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-weight: 700;
		padding-right: 2rem;
	}

	.askingprice__undo {
		border: none;
		font-size: 1.2rem;
	}
}

@import 'styles/variables';

.adminpages__users {
	height: 100%;
	width: 100%;
	padding: 2rem;

	.relative {
		position: relative;
	}
	.copy-alert {
		position: absolute;
		background-color: gray;
		color: white;
		border: solid 1px;
		top: 0;
		right: 0;
		padding: 0.2rem 0.5rem;
		z-index: 10;
	}
	.loading {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.loadingdescription {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;
		}

		.loadingbarouter {
			height: 1rem;
			width: 30%;
			background: white;
			border: solid 1px var(--secondary-color-dark);
			border-radius: 8px;
			overflow: hidden;
		}

		.loadingbarinner {
			background: var(--admin-accent-1);
			height: 100%;
			width: 100%;
			border-radius: 8px;
			transition: all 1s;
			transform: translateX(-100%);
		}
	}

	&__detailsaction,
	&__paddleaction {
		border: none;
		padding: 0.5rem 1rem;
		border-radius: 12px;
		white-space: nowrap;
	}

	&__modal {
		position: fixed;
		height: 100vh;
		width: 100vw;
		top: 0;
		left: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		&__shadow {
			position: absolute;
			height: 100%;
			width: 100%;
			background-color: black;
			opacity: 0.4;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__inner {
			position: relative;
			height: 70%;
			width: 60%;
			z-index: 2;
			opacity: 1;
			padding: 2rem;
			border-radius: 12px;
			background-color: white;
			opacity: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			text-transform: capitalize;
		}

		&__table__wrapper {
			height: 80%;

			.paddlenum {
				height: 2rem;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
			}
		}

		.modal__biddingurl {
			position: relative;
			text-transform: none;
			::first-letter {
				text-transform: none;
			}

			.modal__biddingurl__container {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.button-container {
				display: flex;
			}

			.copy-button {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 2rem;
				width: 2rem;
				border-radius: 100%;
				border: 1px solid var(--secondary-color-dark);
				margin-right: 1rem;
				background: var(--primary-color-light);
				color: var(--accent-color-1);

				&:hover {
					background: var(--accent-color-1);
					color: var(--primary-color-light);
				}
			}

			.copy-close {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 2rem;
				width: 2rem;
				border-radius: 100%;
				border: 1px solid var(--secondary-color-dark);
				margin-right: 1rem;
				background: var(--primary-color-light);
				color: var(--warning-color);

				&:hover {
					background: var(--warning-color);
					color: var(--primary-color-light);
				}
			}
		}

		&__table thead tr {
			position: sticky;
			top: 0;
			background-color: #d7dceb;
			z-index: 1;
		}

		&__title,
		&__user {
			width: 100%;
			display: flex;
			justify-content: flex-start;
		}

		&__showall {
			position: absolute;
			top: 2rem;
			right: 2rem;
			padding: 0.5rem 1rem;
			border-radius: 12px;
		}

		&__input {
			border-radius: 12px;
			padding: 0.5rem 1rem;
			width: 10rem;
			border: none;
		}

		&__select {
			height: 2rem;
			width: 3rem;
		}
	}

	&__row {
		width: 100%;
		display: flex;
		padding: 2rem;
	}

	.row-1 {
		justify-content: flex-end;
		height: 10%;
	}

	&__tabs {
		flex-grow: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__tab {
		font-weight: 200;
		margin: 0 2vw;
		cursor: pointer;

		&.isActive {
			font-weight: 400;
		}
	}

	&__tab__spacer {
		height: 100%;
		border-left: solid 4px #d7dceb;
	}

	&__add {
		padding: 0.5rem 1rem;
		border-radius: 8px;
		border: none;
		font-weight: 500;
		text-transform: capitalize;
	}

	&__profile img {
		border-radius: 100%;
	}

	&__info {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding-left: 2rem;
	}

	&__email,
	&__role,
	&__phone {
		font-weight: 400;
	}

	&__lastlogin {
		margin-bottom: 2rem;
	}

	&__control {
		display: flex;
		align-items: flex-end;
	}

	&__control__button {
		padding: 0.5rem 2rem;
		border-radius: 8px;
		font-weight: 500;
	}

	.row-3 {
		padding-left: 0;
	}

	.adminpages__users__dragdrop {
		height: 4rem;
		width: 40%;
		margin-top: 1rem;
	}

	.row-4 {
		padding: 0;
		margin-top: 1rem;
		flex-direction: column;
		height: 90%;
	}

	&__controls {
		width: 100%;
		display: flex;
		align-items: center;

		.clear {
			padding: 0.5rem 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: var(--primary-color-light);
			border: solid 1px var(--warning-color);
			border-radius: 8px;
			color: var(--warning-color);
			font-size: 1.05rem;
			text-transform: capitalize;

			.icon {
				font-size: 1.2rem;
				margin-right: 1rem;
			}

			&:hover {
				background: var(--warning-color);
				color: var(--primary-color-light);
			}
		}
	}

	&__filter,
	&__search {
		display: flex;
		flex-direction: column;
		width: 20%;
		margin-bottom: 1rem;
		margin-right: 2rem;
		padding: 0.5rem 1rem;
		border-radius: 12px;
		text-transform: capitalize;

		label {
			margin-bottom: 0.5rem;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		select,
		input {
			height: 2rem;
			border-radius: 8px;
			font-size: 1rem;
			padding: 0 0.5rem;
		}

		&.active {
			background: var(--admin-accent-1);
		}
	}

	&__table__wrapper {
		margin-bottom: 1rem;
	}

	&__table__wrapper,
	.adminpages__users__modal__table__wrapper {
		width: 100%;
		overflow: auto;
		border-radius: 12px;
		border: 1px solid #d7dceb;
		position: relative;
	}

	.adminpages__users__resetmessage {
		position: fixed;
		height: 10%;
		width: 30%;
		left: 40%;
		top: 45%;
		background-color: white;
		border: solid 2px gray;
		border-radius: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__table,
	.adminpages__users__modal__table {
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		border-style: hidden;
		font-weight: 400;

		thead {
			background-color: #d7dceb;

			tr {
				height: 3rem;
				text-align: left;
			}
		}

		tbody {
			background-color: #edf0f7;
		}

		tr {
			height: 4rem;
		}

		tr:hover {
			background-color: #d7dceb;
		}

		th,
		td {
			padding: 0.5rem 1rem;
		}

		.adminpages__users__table__profile {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 1rem;
				font-size: 1.4rem;
				opacity: 0.6;
			}
		}
	}

	&__row__controls {
		display: flex;
		align-items: center;
		// justify-content: flex-end;

		.delete__confirm {
			margin-right: 1rem;
		}
	}

	&__row__btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		width: 2rem;
		border-radius: 100%;
		border: 1px solid var(--secondary-color-dark);
		margin-right: 1rem;

		&.delete {
			background: var(--warning-color);
			color: var(--primary-color-light);

			&:hover {
				background: var(--primary-color-light);
				color: var(--warning-color);
			}
		}

		&.edit {
			background: var(--admin-accent-2);
			color: var(--primary-color-light);

			&:hover {
				background: var(--primary-color-light);
				color: var(--admin-accent-2);
			}
		}

		&.reset {
			background: var(--lost-color);
			color: var(--primary-color-light);

			&:hover {
				background: var(--primary-color-light);
				color: var(--lost-color);
			}
		}

		&.register {
			margin-left: 1rem;
			background: var(--admin-accent-2);
			color: var(--primary-color-light);

			&:hover {
				background: var(--primary-color-light);
				color: var(--lost-color);
			}
		}

		.adminpages__users__tooltip {
			color: white;
			background-color: gray;
			border-radius: 8px;
			white-space: nowrap;
			visibility: hidden;
			position: absolute;
			padding: 0.2rem 0.5rem;
			top: -24px;
			z-index: 2;
		}
	}

	&__row__btn:hover {
		.adminpages__users__tooltip {
			visibility: visible;
		}
	}

	&__table__row {
		cursor: pointer;
	}

	&__table__row:hover {
		background-color: #ccd9ff;
	}
}

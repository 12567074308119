.container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;

	.title {
		font-weight: 400;
		margin-right: 0.2rem;
		text-transform: capitalize;
	}
}

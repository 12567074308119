.fixednotify {
	position: fixed;
	top: 50%;
	left: 50%;
	padding: 1rem 4rem;
	z-index: 99;
	background: var(--primary-color-light);
	border: solid 2px var(--admin-accent-2);
	border-radius: 8px;
}

@import "styles/variables";

.lazyimage {
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        &__icon {
            font-size: 30px;
            // color: $secondary-color-dark;
            animation-name: spin;
            animation-duration: 3000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
    }

    .loading {
        margin-left: 11.5%;
        margin-top: 5px;
        color: orange;
    }

    .loader {
        animation: 2s linear infinite loader-animation;
        max-width: 50px;
        display: flex;
        position: relative;
        vertical-align: middle;
        margin-left: 0.2%;
        margin-top: 5px;
    }
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes loader-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: #D7DCEB;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
}

@keyframes circle-animation {
    0%, 25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%, 75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
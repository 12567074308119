.container {
	height: 30vmin;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: var(--primary-color-light);
	margin: 1rem 4rem;
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 20px;
	margin-bottom: 2rem;
}

.item {
	background: var(--background-color);
	padding: 1vmin;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 24px;
}

.green {
	color: var(--admin-accent-1);
}

.blue {
	color: var(--accent-color-1);
}

.purple {
	color: purple;
}

.subtitleLarge {
	font-size: 3vmin;
}

.subtitleSmall {
	font-size: 1.8vmin;
}

.valueLarge {
	font-size: 8vmin;
	font-weight: 600;
}

.valueSmall {
	font-size: 3.5vmin;
	font-weight: 600;
}

.streamBidValueSold {
	grid-area: 1 / 1 / 3 / 3;
}
.streamBidPercentSold {
	grid-area: 1 / 3 / 3 / 5;
}
.streamBidValuePercent {
	grid-area: 1 / 5 / 3 / 7;
}
.valueSold {
	grid-area: 1 / 7 / 2 / 8;
}
.otherValueSold {
	grid-area: 1 / 8 / 2 / 9;
}
.inRoomValueSold {
	grid-area: 1 / 9 / 2 / 10;
}
.minLotPrice {
	grid-area: 2 / 7 / 3 / 8;
}
.avgLotPrice {
	grid-area: 2 / 8 / 3 / 9;
}
.maxLotPrice {
	grid-area: 2 / 9 / 3 / 10;
}

@import 'styles/variables';

.addauction__details {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1rem 0;
	height: 100%;
	width: 100%;

	.confirmation__shadow {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background-color: black;
		opacity: 0.2;
	}

	.confirmation {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding-top: 5%;
	}

	.confirmation__popup {
		height: 50%;
		width: 60%;
		border-radius: 12px;
		-webkit-box-shadow: 1px 13px 50px 12px rgba(0, 0, 0, 0.64);
		box-shadow: 1px 13px 50px 12px rgba(0, 0, 0, 0.64);
	}

	.confirmation__header {
		height: 20%;
		width: 100%;
		padding: 0 2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 12px 12px 0 0;
		text-transform: capitalize;
	}

	.confirmation__body {
		height: 60%;
		width: 100%;
		padding-top: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		word-wrap: anywhere;
	}

	.confirmation__footer {
		height: 20%;
		width: 100%;
		border-radius: 0 0 12px 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.confirmation__error {
		display: flex;
		align-items: center;
		visibility: hidden;

		.icon {
			margin-right: 1rem;
		}
	}

	.confirmation__control {
		padding: 0.5rem 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 2rem;
		border-radius: 8px;
		text-transform: capitalize;

		.icon {
			margin-right: 1rem;
		}
	}

	&__row-1 {
		width: 100%;
		height: 10%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 2rem;

		&__header {
			&__title {
				font-size: 1.1rem;
				font-weight: bold;
				margin-bottom: 0.5rem;
			}
		}

		&__action {
			height: 3rem;
			width: 10rem;
			border: none;
			border-radius: 24px;
			text-transform: capitalize;
			font-size: 1rem;

			&-icon {
				font-size: 0.9rem;
				margin-left: 1rem;
			}

			&.goauction {
				margin-right: 1rem;
			}

			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}

	&__row-2 {
		display: flex;
		height: 100%;
		width: 100%;
		border-top: 1px solid #d7dceb;

		&__col-1,
		&__col-2 {
			height: 100%;
			width: 50%;
			background-color: white;
			padding: 2rem;
		}

		&__col-1 {
			border-right: none;
		}

		&__col-2 {
			display: flex;
			flex-direction: column;

			.title,
			.subtitle {
				margin: 0 0 1rem 0;
			}

			.cover__image {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 12px;
				padding: 1rem;

				&__info {
					margin-top: 1rem;
				}

				img {
					max-width: auto;
					max-height: 5rem;
				}
			}

			.dragdropinput {
				margin-top: 2rem;
				margin-bottom: 2rem;
				height: 4rem;

				.dragdropinput__input {
					height: 4rem;
				}

				&__file {
					border: solid 1px #d7dceb;
					border-radius: 12px;
					padding: 1rem;
				}
			}
		}
	}
}

.mobilemainpanel {
	height: 40%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow: hidden;
}

.mobilemainpanel__overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		color: white;
		font-size: 3rem;
	}
}

.mobilemainpanel__live {
	position: absolute;
	z-index: 1;
	top: 5%;
	left: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.4rem;
	border-radius: 4px;
	text-transform: uppercase;

	.icon {
		margin-right: 0.2rem;
	}
}

.mobilemainpanel__lotno {
	position: absolute;
	z-index: 1;
	top: 20%;
	left: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.2rem 0.4rem;
	border-radius: 4px;
}

.mobilemainpanel__thumbnail__overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 102;
}

.mobilemainpanel__video {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobilemainpanel__image {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 100%;
		max-width: 100%;
	}
}

.mobilemainpanel__thumbnail {
	position: absolute;
	left: 5%;
	bottom: 5%;
	height: 12vh;
	width: 12vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 101;
	overflow: hidden;

	img {
		object-fit: cover;
	}
}

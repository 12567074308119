.auctioneer .currentbid {
	width: 95%;
	height: 30%;

	.currentbid__description__title {
		font-size: 3rem !important;
	}

	.currentbid__value {
		font-size: 4rem !important;
		width: 50%;
	}
}

.mobile-passedoffer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	position: relative;

	&.open {
		padding-bottom: 20%;
	}

	&-closed {
		height: 80%;
		max-height: 3rem;
		width: 90%;
		border-radius: 12px;
		border: solid 1px var(--primary-color-dark);
		color: var(--primary-color-dark);
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
	}
}

.mobile-passedoffer-input {
	// height: 2rem;
	text-align: center;
	width: 80%;
	font-size: 1.4rem;
}

.mobile-passedoffer-action {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 30%;
	max-height: 4rem;
	color: var(--primary-color-light);
	border: none;
	border-radius: 40px;
	font-size: 20px;
	font-weight: 600;
	z-index: 1;
	margin: 1% 0;
	background-color: var(--primary-color-dark);
	border: solid 1px var(--secondary-color-dark);
	text-transform: uppercase;
}

.focus-message {
	position: absolute;
	top: 25%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.body {
		width: 40%;
		padding: 2rem;
		background: var(--primary-color-light);
		border-radius: 12px;
		border: solid 2px var(--admin-accent-2);
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			font-size: 2rem;
			margin-right: 1rem;
			color: var(--admin-accent-2);
		}
	}
}

.container {
	display: flex;
	width: 100%;
	height: 3.2rem;
	justify-content: flex-start;
	position: relative;
	overflow: hidden;
}

.streambid {
	transform: scale(1.05);
}

.feedItem {
	position: absolute;
	top: 1rem;
	min-height: 2.2rem;
	display: flex;
	align-items: center;
	width: fit-content;
	padding-left: 0.5rem;
	font-weight: 400;
	letter-spacing: 0.0093vw;
	border: solid 1px var(--secondary-color-dark);
	left: 1rem;
	border-radius: 12px 12px 12px 0;
}

.self {
	left: unset;
	right: 1rem;
	border-radius: 12px 12px 0 12px;
	background-color: var(--secondary-color-light);
}

.animateEntry {
	animation: slide-in-left 0.5s;
}

.animateEntrySelf {
	animation: slide-in-right 0.5s;
}

.paddleNum {
	font-weight: 600;
	text-decoration: underline;
}

.sourceIcon {
	height: 15px;
	width: 15px;
	margin-left: 16px;
}

.description {
	margin-left: 12px;
	margin-right: 16px;
}

.deleted {
	opacity: 0.6;
}

.lineThrough {
	text-decoration: line-through;
}

@keyframes slide-in-left {
	0% {
		left: -100%;
	}
	50% {
		left: 1rem;
	}
	100% {
		left: 1rem;
	}
}

@keyframes slide-in-right {
	0% {
		right: -100%;
	}
	50% {
		right: 1rem;
	}
	100% {
		right: 1rem;
	}
}

.bidsFeed {
	height: 40%;
	width: 100%;
	overflow: hidden;
	position: relative;
}

.slideBox {
	position: absolute;
	height: 100%;
	width: 100%;
}

.slideDown {
	transition: all 0.5s;
	padding-top: 8vh;
}

.autobidOpen {
	height: 20%;
}

.noBids {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auctioneer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.quadrant {
		height: 50%;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		font-size: 2rem;
		border: solid 1px var(--secondary-color-dark);
	}

	.quadrant-1 {
		// background: cornflowerblue;
	}

	.quadrant-2 {
		// background: chocolate;
	}

	.quadrant-3 {
		// background: rgb(26, 163, 78);
	}

	.quadrant-4 {
		// background: rgb(234, 52, 213);
	}
}

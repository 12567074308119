.header__streaminfo {
	position: absolute;
	// bottom: -300px;
	top: 100%;
	right: 1px;
	// height: 300px;
	padding: 2rem 1rem;
	width: 900px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
}

.streaminfo__header {
	height: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.streaminfo__items {
	height: 80%;
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.streaminfo__item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 12px;
	padding: 1rem;
	margin: 1rem 3rem;

	.value {
		word-break: break-all;
		padding: 1rem;
	}
	.value::first-letter {
		text-transform: lowercase;
	}

	.start-stop button {
		height: 2rem !important;
		width: 5rem !important;
		border-radius: 8px;
		margin-left: 1rem;
	}

	&.streampin {
		display: flex;
		flex-direction: column;
	}

	.request-pin {
		height: 2rem !important;
		width: 5rem !important;
		border-radius: 8px;
	}

	.streampin-inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.copy {
		position: relative;

		.copy__icon {
			cursor: pointer;
		}

		.copy__success {
			visibility: hidden;
			position: absolute;
			white-space: nowrap;
			top: -20px;
			left: -50px;
			padding: 2px 4px;
			border-radius: 8px;

			&.show {
				visibility: visible;
			}
		}
	}
}

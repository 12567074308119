.itemdetail {
	height: 90%;
	width: 100%;
	padding-left: 1rem;

	.itemdetail__main {
		height: 85%;
		width: 100%;
		display: flex;
	}

	.mainimage {
		height: 100%;
		width: 45%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.thumbnails {
		height: 100%;
		width: 10%;
		overflow-y: auto;
		padding-right: 0.3rem;

		.thumbnail {
			width: 100%;
			height: 25%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			img {
				max-width: 100%;
				max-height: 100%;
				margin-top: 0.25rem;
			}

			img:hover {
				cursor: pointer;
			}

			&:hover {
				border: solid 1px var(--secondary-color-dark);
			}
		}
	}

	.details {
		height: 100%;
		width: 45%;
		margin: 0;
		padding: 1rem;
		overflow-y: auto;

		.itemdetail__lotnumber,
		.itemdetail__title,
		.itemdetail__estimate {
			font-weight: 600;
			margin-bottom: 1rem;
		}
	}

	.itemdetail__footer {
		height: 12%;
		width: 100%;
		box-shadow: 0px 0px 10px 2px #00000014;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		margin-top: 3vh;
	}
}

.container {
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.controls {
	position: absolute;
	height: 100%;
	left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	height: 2.2rem;
	width: 2.2rem;
	background: var(--primary-color-light);
	border-radius: 8px;
	border: 1px solid var(--secondary-color-dark);
	font-size: 1rem;
}

.edit {
	margin-left: 1rem;
}

.input {
	height: 3rem;
	width: 100%;
	border: none;
	border-radius: 8px;
	background: var(--secondary-color-light);
	text-align: right;
	font-size: 1.2rem;
	text-transform: capitalize;
	padding-right: 1rem;
}

.info {
	border: solid 2px var(--admin-accent-2);
}

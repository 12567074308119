@import 'styles/variables';

.lotcard {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	padding-top: 1vh;
	margin-top: 1px;
	padding-bottom: 2.22vh;
	border-radius: 12px;
	cursor: pointer;
	white-space: nowrap;

	// &:hover {
	//     border: solid 1px #D7DCEB;
	// }

	&:hover &__image img {
		transform: scale(1.05);
	}

	> .lotcard__image {
		// max-height: 15.5vh;
		height: 60%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1vh;
		overflow: hidden;
		perspective: 1000px;

		// &:hover .lazyimage__inner {
		//     transform: rotateY(180deg);
		// }

		.lazyimage__inner {
			position: relative;
			width: 100%;
			height: 100%;
			text-align: center;
			transition: transform 0.6s;
			transform-style: preserve-3d;

			&.itemwon {
				transform: rotateY(180deg);
			}

			.lazyimage__front {
				position: absolute;
				width: 100%;
				height: 100%;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					transition-duration: 0.3s;
				}
			}

			.lazyimage__back {
				position: absolute;
				width: 100%;
				height: 100%;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transform: rotateY(180deg);
				display: flex;
				align-items: center;
				justify-content: center;

				.icon {
					font-size: 50px;
					color: var(--wonitem-highlight);
				}
			}
		}
	}

	> .lotcard__details {
		margin: 1% 10% 0 0;
		// width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.lotcard__details__lotnumber {
			margin: 3% 0 0 5%;
			// font-size: $small-14-font-size;
			font-weight: 400;
		}

		.lotcard__starred {
			// color: $warning-color;
			// color: $primary-color-dark;
			transition: color 0.1s;
		}

		.lotcard__starred__icon.active {
			// color: $warning-color;
			transition: color 0.1s;
		}
	}

	> .lotcard__title {
		margin: 1% 3% 0 5%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 0.016vw;
	}

	> .lotcard__estimate {
		margin: 1% 3% 0 5%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// font-size: $small-14-font-size;
		font-weight: 400;
	}

	> .lotcard__info {
		height: 3vh;
		position: absolute;
		border-radius: 0.5vh;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		display: flex;
		justify-content: center;
		align-items: center;
		// color: white;
		width: 55%;
		text-transform: uppercase;
		font-weight: 400;

		&.currentitem {
			background: var(--accent-color-1);
		}

		&.autobid {
			background: var(--primary-color-dark);
		}

		&.wishlistcard {
			background: var(--wishlist-color);
		}
	}

	.lotcard__info__text {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.getready {
		border-left: solid 1px #d7dceb;
		border-right: solid 1px #d7dceb;
		border-radius: 12px;
	}
}

.lotcard.sold {
	> .lotcard__image {
		opacity: 0.38;
	}

	> .lotcard__lotnumber {
		// color: $lost-color;
	}

	> .lotcard__title {
		// color: $wishlist-color;
	}
}

.lotcard.won {
	> .lotcard__image {
		opacity: 0.38;
	}

	> .lotcard__lotnumber {
		// color: $lost-color;
	}

	> .lotcard__title {
		// color: $admin-accent-1;
	}
}

.lotcard.passed {
	> .lotcard__lotnumber {
		color: #747e99;
	}

	> .lotcard__image {
		opacity: 0.38;
	}

	> .lotcard__estimate {
		font-weight: 600;
	}
}

@import 'styles/variables';

.addauction__complete {
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 1rem;

	&__row-1 {
		position: relative;

		& .img-container {
			height: 12rem;
			width: 100%;
			overflow: hidden;

			& img {
				min-width: 100%;
				min-height: 100%;
				transform: translate(0, -40%);
			}
		}

		& .overlay {
			position: absolute;
			width: 100%;
			height: 12rem;
			background: rgba(0, 0, 0, 0.3);
			top: 0;
			justify-items: center;
			padding: 0 2rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__col-1,
			&__col-2 {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			&__col-1 {
				align-items: flex-start;

				.auction-title {
					word-wrap: anywhere;
				}
			}

			&__col-2 {
				align-items: flex-end;
				justify-content: space-between;
				height: 10rem;
			}

			& h2 {
				font-weight: normal;
				padding-top: 0.3rem;
			}

			.addauction__runstate {
				color: white;
				font-size: 1rem;
				font-weight: 700;
				border-radius: 12px;
				width: 8rem;
				height: 3rem;
				border: none;
			}

			.addauction__stats {
				.addauction__stats__item {
					display: flex;
					justify-content: space-between;

					span {
						margin-left: 1rem;
					}
				}
			}
		}
	}

	&__row-2 {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 3rem 0 1rem 0;
		font-weight: normal;

		& section {
			padding: 1rem 2rem 1rem 0.5rem;
			border-radius: 1rem;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.copy-alert {
				position: absolute;
				background-color: gray;
				color: white;
				border: solid 1px;
				bottom: 0;
				right: 0;
				padding: 0.2rem 0.5rem;
			}

			& .img-container {
				width: 3rem;
				height: 3rem;
				overflow: hidden;
				border-radius: 50%;
				margin: 0 0.8rem;

				& img {
					width: 100%;
					min-width: 100%;
					min-height: 100%;
				}
			}

			& .icon {
				border-radius: 50%;
				padding: 0.8rem;
				margin: 0 0.8rem;
				font-size: 0.9rem;
				width: 1.5rem;
				height: 1.5rem;
			}

			& .title {
				font-weight: bold;
				margin-bottom: 0.2rem;
				text-transform: capitalize;
			}

			p {
				margin: 0;
				display: flex;
				align-items: center;

				.icon {
					padding: 0.9rem;
					cursor: pointer;
				}
			}

			ul {
				list-style: none;
				padding: unset;
			}
		}

		.golink__start {
			font-size: 1rem;
			font-weight: 700;
			border-radius: 12px;
			padding: 1rem;
			border: none;
		}

		.golink__input {
			margin: 1rem 0.3rem;
			padding: 0.3rem 0.5rem;
			border-radius: 0.5rem;
			border: 1px solid #c6c6c6;
			text-align: center;
		}

		.golink__cancel,
		.golink__confirm {
			font-weight: 700;
			border-radius: 8px;
			padding: 0.3rem 0.5rem;
			margin-left: 0.2rem;
		}

		.golink__confirm {
			background: var(--admin-accent-1);
			color: white;

			&.red {
				background: var(--warning-color);
			}
		}

		& section:nth-child(1) {
			background: #f8f8f8;
		}

		& select {
			height: 2rem;
			padding: 0.3rem;
			border: none;
			background: #e8b573;
			color: white;
			border-radius: 0.3rem;
		}
	}

	&__row-3 {
		padding: 1rem;
		border-bottom: #d2d2d2 1px solid;
		font-weight: normal;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.addauction__description {
			width: 70%;
			padding: 1rem;
			overflow-x: hidden;
		}

		.addauction__autobids__btn {
			font-size: 1rem;
			font-weight: 700;
			border-radius: 12px;
			width: 10rem;
			height: 2.5rem;
			border: none;
			background: var(--wishlist-color-offset);
			color: var(--primary-color-light);
			margin-right: 1rem;
		}

		.addauction__registrations__btn {
			font-size: 1rem;
			font-weight: 700;
			border-radius: 12px;
			width: 10rem;
			height: 2.5rem;
			border: none;
			background: var(--admin-accent-1);
			color: var(--primary-color-light);
			margin-right: 1rem;
		}

		.addauction__passbids__btn {
			font-size: 1rem;
			font-weight: 700;
			border-radius: 12px;
			width: 10rem;
			height: 2.5rem;
			border: none;
			background: var(--admin-accent-2);
			color: var(--primary-color-light);
			margin-right: 1rem;
		}

		.addauction__stats__btn {
			font-size: 1rem;
			font-weight: 700;
			border-radius: 12px;
			width: 9rem;
			height: 2.5rem;
			border: none;
		}
	}

	&__row-4 {
		font-weight: normal;
		padding-bottom: 2rem;

		.addauction__lottable {
			width: 100%;
			min-height: 60rem;
		}

		.slider-section {
			padding: 1rem;
			box-shadow: 0 0 1px 1px whitesmoke;
		}

		.round-button {
			margin-left: 1rem;
			padding: 0.3rem 0.5rem;

			border-radius: 0.5rem;
			border: 1px solid #c6c6c6;

			text-align: center;

			&-blue {
				color: #3d6eff;
				margin: 1rem;
				padding: 0.3rem 0.5rem;
				background: white;

				border-radius: 0.5rem;
				border: 1px solid #3d6eff;
			}
		}

		&__left-panel {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 65%;

			&__page-toggle {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	&__row-5 {
		& .img-container {
			width: 5rem;
			height: 5rem;

			& img {
				max-width: 100%;
				max-height: 100%;
			}
		}
		& table {
			border-collapse: collapse;
			width: 100%;
		}

		& th {
			background: #f0f0ff;
			padding: 0.5rem 0.5rem;
		}

		& td {
			padding: 0.5rem 0.5rem;
			font-weight: normal;
			border-bottom: #eeeeee solid 1px;
		}

		& td:nth-child(8n-7) {
			font-weight: bold;
		}

		& td:nth-child(3n) {
			padding: 1rem 0.5rem;
		}

		& td:nth-child(4n),
		& td:nth-child(6n) {
			padding: 1rem 0.5rem;
		}

		& td:nth-child(7n) {
			color: #3d6eff;
			text-align: right;
		}

		& thead,
		& tfoot {
			color: #333;
			text-align: left;
		}
	}
}

.connection {
	position: absolute;
	// bottom: -300px;
	top: 100%;
	right: 1px;
	// height: 300px;
	padding: 2rem 1rem;
	width: 700px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background: var(--primary-color-dark);
	text-transform: capitalize;

	.report {
		width: 90%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: var(--background-color);
		color: var(--primary-color-dark);
		padding: 1rem;
		border-radius: 8px;
		margin: 1rem 0;

		.status .test {
			height: unset;
			width: unset;
			min-width: 6rem;
			padding: 0.8rem 1rem;
			background: var(--secondary-color-dark);
			color: var(--primary-color-dark);
			border-radius: 8px;
		}

		.icon {
			margin-left: 1rem;
			font-size: 1rem;
		}

		.fa-spin {
			margin-left: 0;
		}

		.icon.status-pass {
			color: green;
		}

		.icon.status-fail {
			color: red;
		}
	}
}

.container {
	height: 70%;
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	height: 100%;
	width: 100%;
	border: none;
	border-radius: 24px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	box-shadow: 0 2px 0 rgba(32, 39, 61, 0.2);
	padding: 0 0.2rem;
	text-transform: uppercase;
	font-weight: 700;
	background: var(--accent-color-1);
	color: var(--primary-color-light);
	font-size: 1.1rem;

	&:active {
		transform: scale(1.05);
	}
}

.success {
	background: var(--admin-accent-1);
	color: var(--primary-color-light);
}

.error {
	background: var(--warning-color);
	color: var(--primary-color-light);
}

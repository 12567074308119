.swipepanel {
	height: 10%;
	width: 100%;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	bottom: 0;
	z-index: 1;
	border-radius: 12px 12px 0 0;
	background: var(--primary-color-light);
	box-shadow: 0px -4px 5px 2px rgba(0, 0, 0, 0.12);

	transition: height 0.2s;
	&.open {
		height: 30%;
	}

	&.mid {
		height: 20%;
	}

	&.closed {
		height: 10%;
	}
}

.mobilewelcomemodal {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;

	.mobilewelcomemodal__shadow {
		position: absolute;
		height: 100%;
		width: 100%;
		background: black;
		opacity: 0.6;
	}

	.mobilewelcomemodal__main {
		position: absolute;
		height: 30%;
		width: 90%;
		top: 35%;
		left: 5%;
		border-radius: 13px;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		padding-top: 20%;
		padding: 15% 2% 0 2%;
		text-align: center;
		z-index: 4;
		background-color: var(--primary-color-light);

		.mobilewelcomemodal__button {
			position: absolute;
			height: 30%;
			width: 100%;
			border: none;
			border-top: 1px var(--secondary-color-dark);
			bottom: -1%;
			border-radius: 0 0 12px 12px;
			background: var(--accent-color-1);
			color: var(--primary-color-light);

			&:active {
				background: var(--accent-offset-1);
			}
		}
	}
}

@import 'styles/variables';

.addauction {
	width: 100%;
	height: 100%;
	overflow-y: auto;

	&__row-1 {
		width: 100%;
		height: 10%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2rem 2rem 1rem 2rem;

		&__title .icon {
			margin-left: 1rem;
		}
	}

	&__row-2 {
		width: 100%;
		height: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__row-3 {
		width: 100%;
		height: 80%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 2rem;
	}
}

.auctioneer .currentlot {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	// padding: 1rem;

	.currentlot__header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.8rem !important;
	}

	.currentlot__title {
		width: 100%;
		text-align: center;
		font-size: 2rem !important;
		padding-top: 1rem;
	}

	.currentlot__image {
		height: 85%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			object-fit: contain;
			max-height: 100%;
			max-width: 100%;
		}
	}
}

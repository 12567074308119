@import 'styles/variables';

.addauction__csv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	// padding: 1rem 2rem;
	width: 100%;

	.confirmation {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 5%;
	}

	.confirmation__popup {
		height: 40%;
		width: 50%;
		border-radius: 12px;
		-webkit-box-shadow: 1px 13px 50px 12px rgba(0, 0, 0, 0.64);
		box-shadow: 1px 13px 50px 12px rgba(0, 0, 0, 0.64);
	}

	.confirmation__header {
		height: 20%;
		width: 100%;
		padding: 0 2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 12px 12px 0 0;
	}

	.confirmation__body {
		height: 60%;
		width: 100%;
		padding-top: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		word-wrap: anywhere;
	}

	.confirmation__footer {
		height: 20%;
		width: 100%;
		border-radius: 0 0 12px 12px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.confirmation__error {
		display: flex;
		align-items: center;
		visibility: hidden;

		.icon {
			margin-right: 1rem;
		}
	}

	.confirmation__control {
		padding: 0.5rem 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 2rem;
		border-radius: 8px;
		text-transform: capitalize;

		.icon {
			margin-right: 1rem;
		}
	}

	&__row-1 {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		padding: 1rem 2rem;

		&__header {
			&__title {
				font-size: 1.1rem;
				font-weight: bold;
				margin-bottom: 0.5rem;
			}
		}

		&__action {
			height: 3rem;
			width: 10rem;
			border: none;
			// background-color: #3D6EFF;
			// color: white;
			border-radius: 24px;
			font-size: 1.1rem;

			&.disabled {
				// background-color: #8e8e8e;
				cursor: default;
			}

			&-icon {
				// color: white;
				font-size: 0.9rem;
				margin-left: 1rem;
			}
		}

		.info {
			margin-left: 2vw;
			font-size: 1.2rem;

			&__icon {
				margin-right: 1vw;
			}
		}
	}

	&__row-2 {
		// background-color: white;
		padding: 0 2rem;

		.dragdropinput {
			margin-bottom: 2rem;
			height: 5rem;

			.dragdropinput__input {
				height: 5rem;
			}
		}
	}

	&__row-3 {
		// background: white;
		padding: 0 2rem 2rem 2rem;
		display: flex;
		align-items: center;
		border-bottom: solid 1px lightgray;
		position: relative;

		.file {
			font-weight: bold;
			display: flex;
			align-items: center;

			&-icon {
				color: blue;
				font-size: 2rem;
				margin-right: 1rem;
			}
		}

		.spacer {
			margin: 0 1rem;
			height: 1.2rem;
			width: 1px;
			border-right: solid gray 1px;
		}

		.results-value {
			color: blue;
		}

		.file__remove {
			margin-left: 20px;
			background-color: white;
			border: none;

			&__icon {
				font-size: 20px;
				color: red;
			}
		}
	}
}

//test stuff
.addauction__csvtest {
	width: 70%;
	margin-top: 5rem;
	background-color: white;

	.csvtest__title {
		font-size: 1.1rem;
		margin-bottom: 1rem;
	}

	.dragdropinput__input,
	.dragdropinput__file {
		min-height: 10rem;
		margin: 1rem;
		width: 40rem;
	}

	.csvtest__action {
		padding: 1rem;
	}
}

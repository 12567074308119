.container {
	margin-top: 2%;
	height: 16%;
	width: 100%;
	padding: 0 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	opacity: 0.9;
	width: 60%;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-transform: capitalize;
}

.autobidvalue {
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px; /* 160% */
	letter-spacing: 0.15px;
}

.subtitle {
	margin-left: 1rem;
	font-size: 0.8rem;
	opacity: 0.7;
}

.control {
	background: var(--primary-color-light);
	border: solid 1px var(--primary-color-dark);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	min-width: 9rem;
	min-height: 2.6rem;
	border-radius: 99px;
	text-align: center;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.25px;
	text-transform: uppercase;
	padding: 10px 20px;
	gap: 10px;

	&:hover {
		outline: 1px solid var(--primary-color-dark);
	}
}

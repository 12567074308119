// panel animations
.biddermobile .mobilelotdetail {
	transition: height 0.2s;
	// &.open {
	//     height: 75%;
	// }

	// &.mid {
	//     height: 65%;
	// }

	// &.closed {
	//     height: 55%;
	// }
}

.biddermobile .biddermobile__futureautobid {
	transition: height 0.2s;
	&.open {
		height: 30%;
	}

	&.mid {
		height: 20%;
	}

	&.closed {
		height: 10%;
	}
}

@media only screen and (max-height: 500px) {
	.biddermobile .biddermobile__futureautobid.open,
	.biddermobile .biddermobile__mobilebidpanel {
		min-height: 50vh;
	}
}

.biddermobile__futureautobid {
	width: 100%;
	// background-color: aquamarine;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	bottom: 0;
	z-index: 1;
	border-radius: 12px 12px 0 0;
	box-shadow: 0px -4px 5px 2px rgba(0, 0, 0, 0.12);
}

.biddermobile {
	height: 100%;
	width: 100%;
	position: relative;

	section {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon {
			// font-size: 2rem;
		}
	}

	.mobileactionbar {
		position: relative;
		z-index: 2;
	}

	&__header {
		height: 8%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 1rem;

		.biddermobile__logo {
			width: 50%;

			img {
				height: 100%;
				width: 100%;
			}
		}

		.biddermobile__topcontrols {
			width: 35%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.icon {
				font-size: 1.5rem;
				margin-left: 1.5rem;
			}
		}
	}

	&__mobilebidpanel {
		position: fixed;
		height: 45%;
		bottom: 0;
	}

	&__logoutcontainer {
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__logouttitle {
		font-size: 1.5rem;
		text-align: center;
		margin-bottom: 10%;
	}

	&__logoutcontrols {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
	}

	&__logoutbutton {
		font-size: 1.2rem;
		padding: 2vh 8vw;
		font-weight: 500;
		border-radius: 40px;
	}
}

.askingPrice {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	// padding: 0.8rem 4vw;
	// border-radius: 12px;
	// margin: 1% 0;
	// background: var(--secondary-color-light);

	.title {
		font-size: 1.2rem;
	}

	.value {
		font-size: 1.5rem;
		width: 40%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

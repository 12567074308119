.catalogue__search {
	height: 100%;
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--primary-color-dark);

	input {
		height: 2rem;
		padding-left: 0.4rem;
		border-radius: 8px;
		border: solid 1px var(--secondary-color-dark);
		outline: none;
	}

	.refine,
	.price {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-evenly;
		padding-left: 1rem;
	}

	.refine {
		height: 15%;

		.inputs {
			width: 80%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			input {
				width: 85%;
			}
		}
	}

	.price {
		height: 15%;

		.inputs {
			width: 80%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			input {
				width: 40%;
			}
		}
	}

	.categories {
		height: 70%;
		width: 100%;
		padding-top: 1rem;
		padding-left: 1rem;
		overflow-y: auto;

		.subtitle {
			height: 2rem;
		}

		.category {
			width: 100%;
			height: 2.5rem;
			display: flex;
			align-items: center;

			input {
				width: 1.2rem;
				accent-color: var(--primary-color-dark);
				color: var(--primary-color-light);
				margin-right: 1rem;
			}
		}
	}
}

.container {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-radius: 8px;
	transition: background 0.5s;
}

.catalogue__header {
	height: 20%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: var(--primary-color-dark);

	.catalogue__spacer {
		width: 25%;
		border-bottom: solid 1px var(--secondary-color-dark);
	}
}

.header {
	height: 10%;
	padding: 0 4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.backButton {
	padding: 0.5rem 1rem;
	border: 1px solid var(--secondary-color-dark);
	color: var(--primary-color-light);
	background: var(--admin-accent-1);
	border-radius: 12px;
}

.exportButton {
	padding: 0.5rem 1rem;
	border: 1px solid var(--secondary-color-dark);
	color: var(--primary-color-light);
	background: var(--accent-color-1);
	border-radius: 12px;
}

.currentLot {
	position: absolute;
	bottom: 2vh;
	left: 4vw;
	right: 4vw;
	height: 29%;
	z-index: 109;
	border-radius: 8px;
	border: 0.8px solid rgba(255, 255, 255, 0.5);
	background: rgba(159, 159, 159, 0.2);
	box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
	backdrop-filter: blur(10px);
	color: var(--primary-color-light);
	display: flex;
	padding: 1vmin;
	align-items: center;
	gap: 16px;
}

.image {
	height: 100%;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1vmin;

	img {
		max-height: 100%;
		max-width: 100%;
	}
}

.details {
	height: 100%;
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	.lotNumber {
		font-family: Roboto;
		font-size: 4.5vmin;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0.15px;
	}

	.title {
		color: var(--primary-color-light);
		font-family: Roboto;
		font-size: 3vmin;
		font-style: normal;
		font-weight: 500;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.currentBidLabel {
		font-family: Roboto;
		font-size: 3vmin;
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.15px;
		text-transform: capitalize;
	}

	.currentBidValue {
		font-family: Roboto;
		font-size: 4.5vmin;
		font-style: normal;
		font-weight: 900;
		letter-spacing: 0.25px;
	}
}

.commission {
	height: 100%;
	width: 48%;
}

.noData {
	height: 80%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	height: 15%;
	font-size: 1.5rem;
	@media (max-width: 1600px) {
		font-size: 1.3rem;
	}
}

.tableContainer {
	width: 100%;
	height: 85%;
	overflow-y: auto;
	border: solid 1px var(--secondary-color-dark);
	border-radius: 8px 8px 0px 0px;
}

.table {
	width: 100%;
	max-height: 100%;
	text-align: left;
	table-layout: fixed;
	border-collapse: collapse;
	font-size: 1.1rem;
	@media (max-width: 1600px) {
		font-size: 1rem;
	}
}

thead {
	background: var(--secondary-color-dark);
	tr th {
		height: 2rem;
		padding: 0 1rem;
	}
}

tr {
	td {
		height: 2rem;
		padding: 0 1rem;
	}
}

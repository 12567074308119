@import 'styles/variables';

.messagepanel {
	width: 100%;
	height: 100%;

	&__header {
		font-weight: 700;
		letter-spacing: 0;
		height: 20%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__container {
		border-radius: 12px 12px 0 0;
		position: relative;
		padding: 1%;
		height: 60%;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-wrap: wrap;
		overflow: auto;

		.buttons__premade {
			background-color: white;
			border-radius: 12px;
			font-weight: 400;
			padding: 1% 2%;
			margin: 0.2rem;
			display: flex;
			text-transform: capitalize;

			&#FAIR_WARNING {
				border: 1px solid #eb5e6c;
				background-color: #ffe0e3;
			}

			&#LAST_CALL {
				border: 1px solid #eb5e6c;
			}
		}
	}

	.messagepanel__manualinput {
		height: 40%;
		border-radius: 0 0 24px 24px;
		display: flex;
		align-items: center;

		&__input {
			height: 100%;
			width: 100%;
			border: none;
			border-radius: 0 0 24px 24px;
			font-weight: 400;
			padding-left: 2rem;
			padding-right: 1rem;

			&:focus {
				outline: none;
			}
		}

		&__favorite {
			border: none;
			margin-right: 1rem;
		}

		&__action {
			height: 100%;
			width: 10%;
			border-radius: 12px 0 12px 12px;
			border: none;
		}
	}
}

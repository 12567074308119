.auctioneer .bidhistory {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.bidhistory__header {
		width: 100%;
		height: unset;

		.title,
		.connected {
			font-size: 2rem !important;
		}
	}
	.bidhistory__estimated {
		display: none;
	}

	.bids {
		height: 80%;
		overflow: auto;

		.message__spacer {
			height: 0.4rem;
		}

		.item,
		.message {
			margin: 1.5rem;
			display: none;
			width: 80%;
		}

		:nth-child(1),
		:nth-child(2),
		:nth-child(3) {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.item__sourcetype {
			height: 5rem;
			width: 5rem;
			border-radius: 100%;
			margin-right: 2rem;

			.item__sourcetypebids {
				height: 2rem;
				width: 2rem;
				font-size: 2rem;
			}
		}

		.item__sourcetype__icon,
		.item__description,
		.message__text {
			font-size: 3rem !important;
		}

		.item__col-2 {
			display: none;
		}
	}
}

@import 'styles/variables';

.lotsfeed.lotcard__container {
	height: 100%;
	// border-bottom: solid 1px #D7DCEB;
	// background-color: white;

	&.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.2rem;
	}

	&.withdrawn {
		opacity: 0.8;
	}

	&-first {
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	&-last {
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	& img {
		transition-duration: 0.1s;
	}
}

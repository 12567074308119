.welcomemodal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	position: relative;
	z-index: 7;
	min-width: 900px;
	overflow: hidden;
	padding: 1%;
	border-radius: 12px;
	height: 100%;
	width: 100%;
	background: var(--primary-color-light);
}

.main {
	border: solid 1px var(--secondary-color-dark);
	height: 90%;
	width: 100%;
	overflow-y: auto;
	padding: 0.5rem;

	iframe {
		width: 80%;
	}
}

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 20%;
	height: 5%;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	font-weight: 600;
}

.decline {
	background: var(--warning-color-light);
	border: solid 1px var(--secondary-color-dark);
}

.accept {
	background: var(--accent-color-2);
	border: solid 1px var(--secondary-color-dark);
}

.biddingpanel__info {
	height: 20%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	font-weight: 400;
	letter-spacing: 0.016vw;

	.biddingpanel__info__message {
		margin-left: 16px;
	}

	.biddingpanel__info__container {
		position: absolute;
		height: 3rem;
		width: 3rem;
		right: 1rem;
		top: -1.5rem;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		outline: 5px solid white;

		&.red {
			border: solid 1px red;
			svg {
				color: red;
			}
		}

		&.amber {
			border: solid 1px darkorange;
			svg {
				color: darkorange;
			}
		}

		&__icon {
			height: 100%;

			&.iClass {
				// font-size: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&.green {
				color: green;
			}

			&.red {
				color: red;
			}

			&.amber {
				color: darkorange;
			}
		}
	}
}

.loading {
	height: 100%;
	width: 35%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.currentLot {
	height: 100%;
	width: 35%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;

	.image {
		height: 100%;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.details {
		height: 100%;
		width: 47%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-evenly;

		.liveNow {
			background: #edeff2;
			padding: 1vmin;
			font-size: 1.2vmin;
			font-weight: 700;
			border-radius: 4px;
			text-transform: uppercase;

			.icon {
				color: var(--warning-color);
				margin-right: 1vmin;
			}
		}

		.lotNumber {
			color: #000;
			font-family: Roboto;
			font-size: 2.7vmin;
			font-style: normal;
			font-weight: 700;
			line-height: 36px; /* 150% */
			letter-spacing: 0.15px;
		}

		.title {
			overflow: hidden;
			color: #000;
			text-overflow: ellipsis;
			font-family: Roboto;
			font-size: 2vmin;
			font-style: normal;
			font-weight: 500;
			line-height: 28px; /* 155.556% */
			letter-spacing: 0.25px;
		}

		.currentBidLabel {
			color: #000;
			font-family: Roboto;
			font-size: 2vmin;
			font-style: normal;
			font-weight: 400;
			line-height: 28px; /* 155.556% */
			letter-spacing: 0.15px;
			text-transform: capitalize;
		}

		.currentBidValue {
			color: #000;
			font-family: Roboto;
			font-size: 4vmin;
			font-style: normal;
			font-weight: 900;
			line-height: 40px; /* 125% */
			letter-spacing: 0.25px;
		}
	}
}

@import "styles/variables";

.clerkconsole {
    // background-color: $background-color;
    width: 100%;
    height: 100%;

    .clerkconsole-container {
        width: 95%;
        height: 90%;
        max-width: 1920px;
        display: block;
        margin-inline: auto;
    }

    .clerkconsole-col-container {
        width: 100%;
        height: 100%;
        max-width: 1920px;
        display: inline-flex;
        justify-content: space-between;

        .clerkconsole__col-1 {
            width: 48%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .clerkconsole__col-1__row-1,
            .clerkconsole__col-1__row-2,
            .clerkconsole__col-1__row-3 {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
            }

            .clerkconsole__col-1__row-1 {
                height: 48%;
            }

            .clerkconsole__col-1__row-2 {
                height: 33%;
            }

            .clerkconsole__col-1__row-3 {
                height: 15%;
            }
        }

        .clerkconsole__col-2 {
            width: 49%;
            height: 100%;
        }
    }
}


.infoicon {
	// overflow: hidden;
	// outline: solid 10px #33D67C;
	background: white;
}

.infoicon__pulse {
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 100%;
	// background: green;
	z-index: 1;

	&.animate-pulse {
		animation: pulsecolor 1.2s ease infinite;
		@keyframes pulsecolor {
			0% {
				transform: scale(1, 1);
			}
			50% {
				opacity: 0.3;
			}
			100% {
				transform: scale(1.5);
				opacity: 0;
			}
		}
	}
}

.infoicon__current {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	z-index: 3;
	background: white;
	border-radius: 100%;

	svg {
		height: 2rem;
		width: 2rem;
	}

	&.animate-transition {
		animation: animateshrink 0.3s linear;
		@keyframes animateshrink {
			50% {
				font-size: 0.6rem;
				z-index: 2;
			}
			100% {
				font-size: 0rem;
			}
		}
	}
}

.infoicon__next {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	z-index: 2;
	background: white;
	border-radius: 100%;

	&.animate-transition {
		animation: animategrow 0.3s linear;
		@keyframes animategrow {
			50% {
				font-size: 0.6rem;
				z-index: 3;
			}
			100% {
				font-size: 1rem;
			}
		}
	}
}

.grid {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 0fr 0fr 0fr 0fr 0fr 0fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 0px;
	transition: 300ms;
}

.layout_1 {
	grid-template-columns: 0fr 0fr 0fr 0fr 0fr 0fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
	.currentlot,
	.bidpanel,
	.bidhistory {
		transition: 300ms;
		padding: 1rem 1.5rem;
	}
}

.layout_2 {
	grid-template-columns: 0fr 0fr 0fr 0fr 1fr 1fr 0fr 0fr 1fr 1fr 1fr 0.5fr;
	.bidpanel,
	.bidhistory {
		transition: 300ms;
		padding: 1rem 1.5rem;
	}
}

.layout_3 {
	grid-template-columns: 1fr 1fr 1fr 1fr 0fr 0fr 0fr 0fr 1fr 1fr 0fr 0fr;
	.bidpanel {
		transition: 300ms;
		padding: 1rem 1.5rem;
	}
}

.wishlist {
	grid-row-start: 1;
	grid-column-start: 5;
	grid-row-end: 7;
	grid-column-end: 7;
}

.catalogue {
	grid-row-start: 1;
	grid-column-start: 1;
	grid-row-end: 7;
	grid-column-end: 5;
}

.currentlot {
	grid-row-start: 1;
	grid-column-start: 7;
	grid-row-end: 5;
	grid-column-end: 9;
}

.bidpanel {
	grid-row-start: 1;
	grid-column-start: 9;
	grid-row-end: 5;
	grid-column-end: 11;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.bidhistory {
	grid-row-start: 1;
	grid-column-start: 11;
	grid-row-end: 5;
	grid-column-end: 13;
}

.timeline {
	grid-row-start: 5;
	grid-column-start: 7;
	grid-row-end: 7;
	grid-column-end: 13;
	padding: 1rem 1.5rem;
}

.container {
	height: 3rem;
	width: 100%;
	position: relative;
}

.title {
	position: absolute;
	left: 0.5rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
}

.input {
	height: 100%;
	width: 100%;
	border: none;
	border-radius: 8px;
	background: var(--secondary-color-light);
	text-align: center;
	font-size: 1.5rem;
}

.delete {
	position: absolute;
	top: 0;
	right: 0.5rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.deleteButton {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 100%;
	border: none;
	background: var(--primary-color-light);
	font-size: 1.5rem;
}

@import "styles/variables";

.mobilebidpanel__bidsfeed {
    height: 50%;
    width: 100%;
}

.mobilebidsfeed {
    height: 70%;
    width: 100%;
    overflow: hidden;

    .mobilebidsfeed__nobids {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .icon {
            margin-right: 5%;
        }
    }

    &.animate-slide {
        animation: slide-down .2s linear;
        @keyframes slide-down {
            50% {
                padding-top: 1.6rem;
            }
            100% {
                padding-top: 3.2rem;
            }
        }
    }

    .mobilebidsfeed__container {
        height: 2.5rem;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 3%;
        
        &.self {
            justify-content: flex-end;
        }
    }

    .mobilebidsfeed__container.message {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mobilebidsfeed__messagetext {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-color-dark);
        }

        .mobilebidsfeed__messagespacer {
            flex-grow: 1;
            height: 1px;
            background-color: var(--secondary-color-dark);
        }
    }



    .mobilebidsfeed__feeditem {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        min-height: 2.2rem;
        padding-left: 0.5rem;
        border: solid 1px var(--secondary-color-dark);

        &:not(.self) {
            &.animate-entry {
                animation: pop-in-left .2s linear;
            }
            left: 1rem;
            border-radius: 12px 12px 12px 0;

            @keyframes pop-in-left {
                0%{
                    transform: scale(0.1);
                    left: -50%;
                    top: 100%;
                }
                50% {
                    transform: scale(0.5);
                    left: -20%;
                    top: 50%;
                }
                100% {
                    transform: scale(1);
                    left: 1rem;
                    top: 1rem;
                }
            }
        }

        &.self {
            background-color: var(--accent-offset-2);
            right: 1rem;
            border-radius: 12px 12px 0 12px;

            .mobilebidsfeed__feeditemdescription {
                background-color: var(--accent-offset-2);
            }

            &.animate-entry {
                animation: pop-in-right .2s linear;
            }
            
            @keyframes pop-in-right {
                0%{
                    transform: scale(0.1);
                    right: -50%;
                    top: 100%;
                }
                50% {
                    transform: scale(0.5);
                    right: -20%;
                    top: 50%;
                }
                100% {
                    transform: scale(1);
                    right: 1rem;
                    top: 1rem;}
            }
        }
    
        & .notification {
            position: absolute;
            border: white 1px solid;
            background: #EDEFF0;
            padding: .2rem .3rem;
            border-radius: 10px;
            font-weight: normal;
            font-size: 10px;
            bottom: 0; right: calc(50% + 7px);
            transform: translate(50%, 50%);
            display: flex;

            i {
                margin: .15rem .1rem 0 .2rem !important;
                font-size: 14px;

                &.green{color: rgb(0, 128, 0)};
                &.red{color: rgb(128, 0, 0)};
            }

            span{
                display: inline;
                padding: .2rem;
            }

            &.long {
                width: 80%;
            }
        }
    }

    .mobilebidsfeed__feeditemicon {
        font-size: 1rem;
    }

    .mobilebidsfeed__feeditemdescription {
        margin-left: 0rem;
        padding: 1vh 2vw;
        font-weight: 400;

        &.self {
            border-radius: 8px 8px 0px 8px;
        }
    }


}

.mobilebidsfeed__footer {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-weight: 400;
    border-top: solid 1px var(--secondary-color-dark);

    .bidsfeed__footer__user {
        width: 40%;
        text-align: left;
        display: flex;
        align-items: center;

        .fa-user {
            margin-right: 0.5rem;
        }
    }

    .bidsfeed__footer__paddle {
        width: 40%;
        text-align: right;
    }
}
.bidbutton__container {
	height: 40%;
	width: 50%;
	position: relative;
}

.bidbutton__pulse {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: var(--accent-color-1);
	border-radius: 124px;
	animation: bidpulse 1.2s ease infinite;
	@keyframes bidpulse {
		0% {
			transform: scale(1, 1);
		}
		50% {
			opacity: 0.3;
		}
		100% {
			transform: scale(1.3);
			opacity: 0;
		}
	}
}

.biddingpanel__action {
	height: 100%;
	width: 100%;
	border-radius: 124px;
	color: var(--primary-color-light);
	outline: none;
	position: relative;
	font-weight: 700;
	letter-spacing: 0.016vw;
	z-index: 1;
	overflow: hidden;
	border: solid 1px var(--secondary-color-dark);
	background-color: var(--accent-color-1);
	text-transform: uppercase;

	.leadbid {
		position: absolute;
		top: 0;
		left: 50%;
		height: 100%;
		width: 0%;
		background-color: var(--wonitem-highlight);
		animation-fill-mode: forwards;
		border-radius: 124px;

		&.live {
			animation: leadbidentry 0.3s linear;
			animation-fill-mode: forwards;
			@keyframes leadbidentry {
				100% {
					width: 100%;
					left: 0%;
				}
			}
		}
	}

	.biddingpanel__action-confirming {
		width: 100%;
		height: 100%;
		border-radius: 124px;
		z-index: -1;
		position: absolute;
		top: 0px;
		transform: translateX(-100%);
		animation: confirming-slider 5s infinite;
	}

	@keyframes confirming-slider {
		0% {
			// width: 35%;
			transform: translateX(-100%);
		}

		50% {
			// width: 100%;
			transform: translateX(0%);
		}

		0% {
			// width: 35%;
			transform: translateX(-100%);
		}
	}

	.biddingpanel__action-title {
		z-index: 3;
		height: 70%;
		width: 100%;
	}

	&:hover {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
	}

	&:active:not(.deactivated) {
		transform: translateY(4px);
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.92);
	}

	&.deactivated {
		box-shadow: none;
		cursor: not-allowed;
	}

	&.fairwarning {
		background-color: red;
	}
}

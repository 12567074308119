@import 'styles/variables';

.adminpages__settings {
	width: 100%;
	height: 100%;
	padding: 2rem;
	overflow: auto;
	text-transform: capitalize;
	background: var(--background-color);

	.title {
		margin-bottom: 2rem;
	}

	.row {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 2rem;
		box-shadow: 0px 0px 12px #0000000a;
		border-radius: 16px;
		margin-bottom: 2rem;
		background: var(--primary-color-light);
		word-break: break-all;
	}

	.row__title {
		margin-bottom: 1rem;
	}

	.openpricemode {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		select {
			margin-left: 2rem;
			padding: 0 1rem;
			height: 1.8rem;
			background: var(--primary-color-light);
			border: solid 1px var(--secondary-color-dark);
			border-radius: 8px;
			cursor: pointer;
		}

		.openpricemode-info {
			margin-left: 2rem;
			height: 1.8rem;
			width: 1.8rem;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			&.loading {
				background: var(--admin-accent-2);
				color: var(--primary-color-light);
			}

			&.success {
				background: var(--admin-accent-1);
				color: var(--primary-color-light);
			}

			&.failure {
				background: var(--warning-color);
				color: var(--primary-color-light);
			}
		}
	}

	.introvideo {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.row__title {
			margin: unset;
			margin-right: 1rem;
		}

		.introvideo__input {
			padding: 0.5rem 1rem;
			width: 30%;
			border: solid 1px var(--secondary-color-dark);
			border-radius: 8px;
		}

		.introvideo__action {
			height: 1.8rem;
			width: 1.8rem;
			margin-left: 1rem;
			border-radius: 8px;
		}
	}

	.autoaccept,
	.salenote {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.autoaccept-toggle,
		.salenote-toggle {
			margin-left: 2rem;
			height: 2rem;
			width: 6rem;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: solid 1px var(--secondary-color-dark);
			background: var(--admin-accent-2);
			color: var(--primary-color-light);
			font-weight: 600;
			text-transform: capitalize;

			&.enabled {
				background: var(--admin-accent-1);
			}

			&.disabled {
				background: var(--warning-color);
			}
		}
	}

	.protocol {
		width: 70%;
		display: flex;
		align-items: center;
		margin-top: 1rem;
		// justify-content: space-evenly;
	}

	.protocol__title {
		width: 20%;
	}

	.protocol__value {
		padding: 0.5rem 1rem;
		width: 60%;
		border-radius: 8px;
	}

	.protocol__reset,
	.protocol__submit {
		height: 1.8rem;
		width: 1.8rem;
		margin-left: 1rem;
		border-radius: 8px;
	}

	.row-3,
	.row-4 {
		display: flex;
		flex-direction: row;
	}

	.tandc__container,
	.regtobidmail__container {
		width: 60%;
		display: flex;
		flex-direction: column;
		// height: 30vh;

		.RichTextEditor__editor___1QqIU {
			min-height: 20vh;
		}
	}

	.tandc__actioncontainer,
	.regtobidmail__actioncontainer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 1rem;
	}

	.tandc__action,
	.regtobidmail__action {
		padding: 0.5rem 1rem;
		border-radius: 8px;

		.icon {
			margin-left: 1rem;
		}
	}
}

.container {
	position: relative;
	display: inline-block;
	color: white;
	background-color: var(--accent-color-1);
	padding: 0.8rem 1.5rem;
	font-size: 16px;
	font-weight: 700;
	border-radius: 12px;
}

.icon {
	margin-left: 1rem;
}

.dropdown {
	display: none;
	position: absolute;
	transform: translateY(0.7rem);
	left: 0;
	width: 100%;
	border-radius: 0 0 12px 12px;
	overflow: hidden;
}

.container:hover {
	border-radius: 12px 12px 0 0;
}

.container:hover .dropdown {
	display: flex;
	flex-direction: column;
	background-color: var(--accent-color-1);
	font-size: 16px;
	z-index: 1;
	cursor: pointer;
}

.item {
	padding: 1rem 1.5rem;

	color: white;
	text-decoration: none;
}

.item:hover {
	background-color: var(--primary-color-dark);
}

@import 'styles/variables';

.setincrements {
	height: 12%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	border-radius: 12px;
	position: relative;
	padding: 1% 2%;

	&__title {
		font-weight: 700;
		height: 25%;
	}

	.info {
		text-align: center;
	}

	&__controls {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> .setincrements__controls__buttons {
			width: 35%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			> .button__container {
				height: 100%;
				width: 25%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;

				> .button {
					background-color: white;
					border-radius: 8px;
					width: 100%;
					height: 50%;
					// padding: 0.8rem 1rem;
					font-weight: 700;
					display: flex;
					align-items: center;
					justify-content: center;

					&.is-highlighted {
						border: 2px solid darkgray;
					}

					&:hover:not(.is-active) {
						background-color: #c2cefd;
						color: black;
					}
				}
			}
		}

		.manual__container {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
		}

		&__manualinput {
			overflow: hidden;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type='number'] {
				-moz-appearance: textfield;
			}

			&__input {
				text-align: center;
				border: none;
				font-weight: 700;
				padding: 0.8rem 1rem;

				&:focus {
					outline: none;
				}
			}

			&.is-highlighted {
				border: 2px solid darkgray;
			}
		}

		&__action__container {
			height: 70%;
			width: 15%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__action {
			height: 100%;
			width: 100%;
			border: none;
			border-radius: 24px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			box-shadow: 0 2px 0 rgba(32, 39, 61, 0.2);
			padding: 0 0.2rem;
			text-transform: uppercase;

			// font-size: $regular-16-font-size;
			font-weight: 700;
			// letter-spacing: .016vw;

			&:active {
				transform: translateY(4px);
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.92);
			}
		}
	}
}

.container {
	height: 100%;
	width: 100%;
}

.lotCard {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
	padding-top: 1vh;
	margin-top: 1px;
	padding-bottom: 2.22vh;
	padding-left: 1vw;
	padding-right: 1vw;
	border-radius: 12px;
	background: var(--primary-color-light);
	cursor: pointer;
	white-space: nowrap;
	text-transform: capitalize;

	&:hover {
		border: solid 1px var(--secondary-color-dark);
	}

	&:hover img {
		transform: scale(1.05);
	}
}

.borderBottom {
	border-bottom: solid 1px var(--secondary-color-dark);
}

.loading {
	height: 100%;
	width: 100%;
	background: var(--primary-color-light);
	display: flex;
	align-items: center;
	justify-content: center;
}

.imageContainer {
	height: 60%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1vh;
	overflow: hidden;
	perspective: 1000px;

	&:hover &__image img {
		transform: scale(1.05);
	}
}

.flipContainer {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.winAnimation {
	// background: greenyellow;
	transform: rotateY(180deg);
	// transition-duration: 0.3s;
}

.imageFront {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		transition-duration: 0.3s;
	}

	.placeholder {
		width: 100%;
		height: 100%;
		background: var(--background-color);
		border-radius: 8px;
	}
}

.imageBack {
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--primary-color-light);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotateY(180deg);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--wonitem-highlight);
	font-size: 3rem;
}

// .image {
// 	width: auto;
// 	max-height: 100%;
// }

.details {
	margin: 1% 10% 0 0;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.lotNumber {
	font-size: var(--small-14-font-size);
	margin: 3% 0 0 5%;
	font-weight: 400;
}

.title {
	margin: 1% 3% 0 5%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.016vw;
}

.estimate {
	margin: 1% 3% 0 5%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: var(--small-14-font-size);
	font-weight: 400;
}

.won {
	color: var(--wonitem-highlight);
}

.lost {
	color: var(--warning-color);
}

.opacity {
	opacity: 0.38;
}

.outer {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.tableWrapper {
	width: 100%;
	height: 85%;
	padding-bottom: 1rem;
	position: relative;
	margin-top: 2rem;
	border-radius: 12px;
	border: 1px solid #d7dceb;
	overflow: auto;
}

.table {
	width: 100%;
	font-weight: normal;
	transition: all 2s;
	thead {
		tr {
			height: 3rem;
			text-align: left;
		}
	}

	th {
		cursor: pointer;
	}

	.icon {
		margin-left: 1rem;
	}

	tr {
		height: 4rem;
	}

	th,
	td {
		padding: 0.5rem 1rem;
	}
}

.sortIcon {
	margin-left: 0.5rem;
}

.row {
	background-color: var(--secondary-color-light);
	cursor: pointer;

	&:hover {
		background-color: #ccd9ff;
	}
}

.rowopen {
	background-color: var(--secondary-color);
	&:hover {
		background-color: var(--secondary-color-dark);
	}
}

.nestedtr {
	height: 0 !important;

	td {
		padding: 0;
		height: 0 !important;
	}
}

.nestedtableWrapper {
	max-height: 0;
	padding: 0 10px;
	box-sizing: border-box;
	overflow: hidden;
	transition:
		max-height 0.3s,
		padding 0.3s;
}

.nestedtableWrapperVisible {
	max-height: 100vh;
	padding: 10px 10px;
	overflow: auto;
	transition:
		max-height 0.8s,
		padding 0.8s;
}

.nestedtable {
	background-color: var(--secondary-color-light);
	th {
		background-color: var(--secondary-color);
		cursor: default;
	}
	tr {
		background-color: white;
		cursor: default;
	}
	td {
		padding: 0.5rem;
	}
}

@import "styles/variables";


.debug__modal {
    height: 1080px;
    width: 1920px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #20273D;

    .shadow {
        height: 1080px;
        width: 1920px;
        position: absolute;
        top: 0px;
        left: 0px;
        opacity: 50%;
        background-color: black;
        z-index: 2;
    }
    
    .modal {
        height: 696px;
        width: 1024px;
        background-color: white;
        z-index: 3;
        border-radius: 12px;
        position: relative;

        &__closebutton {
            height: 40px;
            width: 40px;
            padding: 0px;
            border-radius: 100%;
            border: none;
            background-color: #20273D;
            position: absolute;
            top: 16px;
            right: 16px;

            &__icon {
                font-size: 20px;
                color: white;
            }
        }

        &__debug__output {
            display: flex;
            flex-direction: column;
            padding-left: 3%;
            padding-right: 3%;

            &__item {
                display: flex;
                border: solid 1px;
                min-width: 50%;
                margin-bottom: 1rem;
                font-size: 1.2rem;
                padding: 0.5rem;

                .title {
                    width: 30%;
                }

                // div {
                //     margin-left: 5rem;
                //     font-size: 1.2rem;
                // }
            }

        }

    }
}



.reactplayersb__container {
    background-color: black;
    height: 100%;
    width: 100%;
    position: relative;

    .reactplayersb__overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center; 
        background-color: black;
        opacity: 0.5;
        cursor: pointer;
        z-index: 2;

        .reactplayersb__overlay-icon {
            font-size: 2rem;
            color: white;
        }
    }

    .reactplayersb {
        height: 100%;
        width: 100%;
    }

}

.video-placeholder {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: black;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;

    .video-placeholder-msg {
        position: absolute;
        width: 100%;
        text-align: center;


        -moz-transform:translateX(100%);
        -webkit-transform:translateX(100%);	
        transform:translateX(100%);

        -moz-animation: scrolltext 8s linear infinite;
        -webkit-animation: scrolltext 8s linear infinite;
        animation: scrolltext 8s linear infinite;
    }
}

@-moz-keyframes scrolltext {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-120%); }
}

@-webkit-keyframes scrolltext {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-120%); }
}

@keyframes scrolltext {
    0% { 
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%); 		
    }
    100% { 
        -moz-transform: translateX(-120%);
        -webkit-transform: translateX(-120%);
        transform: translateX(-120%); 
    }
   }
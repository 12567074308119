.controls-panel {
	height: 100%;
	width: 100%;
	border-radius: 8px;
	background: var(--background-color);
	border: solid 1px var(--secondary-color-dark);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.controls-body {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
	}

	.streamid,
	.video-source,
	.audio-source,
	.resolution,
	.publish,
	.info {
		width: 80%;

		button {
			margin: 1rem;
			padding: 0.5rem 1rem;
			border: solid 1px var(--secondary-color-dark);
			border-radius: 8px;

			&.active {
				background: var(--admin-accent-1);
				color: var(--primary-color-light);
			}
		}
	}

	.video-source,
	.audio-source {
		max-height: 20%;

		.title {
			margin-bottom: 1rem;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.filter {
				display: flex;
				align-items: center;
				justify-content: center;

				input {
					height: 1.2rem;
					width: 1.2rem;
					margin: 0 0.5rem 0 1rem;
				}
			}
		}
		.sources {
			border: solid 1px var(--secondary-color-dark);
			height: 80%;
			border-radius: 8px;
			overflow: auto;
			background: white;
		}
	}

	input {
		height: 2rem;
		width: 100%;
		font-size: 1.2rem;
	}

	input,
	.info-body {
		margin: 1rem;
		padding-left: 1rem;
		width: 80%;
		height: 3rem;
		background: white;
		border: solid 1px var(--secondary-color-dark);
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		&.error {
			border: solid 1px var(--warning-color);
			background: var(--warning-color);
			color: var(--primary-color-light);
		}
	}

	.publish button {
		width: 20%;
		&.live {
			background: var(--admin-accent-1);
			color: var(--primary-color-light);
		}

		&.working {
			background: var(--admin-accent-2);
			color: var(--primary-color-light);
		}

		&.stop {
			background: var(--warning-color);
			color: var(--primary-color-light);
		}
	}

	.log {
		margin: 1rem;
		width: 80%;
		height: 30%;

		.output {
			margin-top: 1rem;
			height: 90%;
			width: 100%;
			background: black;
			color: white;
			overflow-y: auto;
			border-radius: 8px;
			padding: 1rem;

			.logitem {
				font-size: 1.1rem;
				padding: 0.2rem 1rem;
			}
		}
	}
}

.star {
	transition: color 0.1s;
	color: var(--warning-color);
}

.hidden {
	visibility: hidden;
}

.active {
	visibility: visible;
}

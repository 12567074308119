@import 'styles/variables';

.bids > .item {
	font-size: 0.8rem;
	margin-bottom: 1rem;
	height: 20%;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	text-transform: capitalize;

	&.void .item__status {
		opacity: 0.5;
	}

	.item__col-1 {
		display: flex;
		align-items: center;

		.item__sourcetype {
			position: relative;
			height: 1.8rem;
			width: 1.8rem;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			.item__sourcetypebids {
				position: absolute;
				top: -0.5rem;
				right: -0.5rem;
				height: 1rem;
				width: 1rem;
				border-radius: 100%;
				background-color: rgb(184, 8, 8);
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.item__description {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			border-radius: 12px;
			padding: 0.5rem 0.8rem;
			font-weight: 400;
		}
	}

	.item__col-2 {
		display: flex;
		align-items: center;
		position: relative;

		.item__stopwatch {
			height: 1.2rem;
			width: 1.2rem;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			&__tooltip {
				visibility: hidden;
				position: absolute;
				top: -2rem;
				background-color: lightgray;
				padding: 0.5rem 1rem;
				border-radius: 12px;
				z-index: 7;
			}

			&:hover .item__stopwatch__tooltip {
				visibility: visible;
			}
		}

		.item__delete {
			height: 1.2rem;
			width: 1.2rem;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin-left: 0.2rem;
		}
	}
}

.item.void {
	.item__col-1 {
		.item__sourcetype,
		.item__description {
			opacity: 0.38;
		}
	}

	.item__status {
		font-size: 1rem;
	}
}

.item.sold {
	.item__status {
		font-size: 1rem;
		color: green;
	}
}

.item.passed {
	.item__status {
		font-size: 1rem;
	}
}

@import 'styles/variables';

.fullscreen {
	height: 100%;
	width: 100%;
	background: var(--primary-color-light);
}

.header {
	width: 100%;
	height: 8%;
	display: flex;
	align-items: center;
	padding: 0 5%;
	background: var(--primary-color-dark);
}

.ahLogo {
	text-decoration: none;
	font-size: 2rem;
	color: var(--primary-color-light);
}

.body {
	width: 100%;
	height: 92%;
	padding: 1% 5%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.col_1 {
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.lotNumber {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 4.5rem;
	text-transform: uppercase;

	@media (max-width: 1600px) {
		font-size: 4rem;
	}
}

.mainImage {
	height: 90%;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.col_2 {
	height: 100%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.lotTitle {
	font-size: 2.2rem;
	font-weight: 400;

	@media (max-width: 1600px) {
		font-size: 2rem;
	}
}

.estimate {
	font-size: 2.2rem;
	@media (max-width: 1600px) {
		font-size: 2rem;
	}
}

.value {
	width: 100%;
}

.hammerWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hammer {
	font-size: 4rem;
	font-weight: 400;

	@media (max-width: 1600px) {
		font-size: 3rem;
	}
}

.currentBid {
	font-size: 5rem;
	width: 100%;
	padding: 1rem;
	background: var(--secondary-color-light);
	position: relative;

	@media (max-width: 1600px) {
		font-size: 4rem;
	}
}

.askingPrice {
	font-size: 5rem;
	width: 100%;
	padding: 1rem;
	background: var(--secondary-color-light);
	position: relative;
	color: var(--accent-color-1);

	@media (max-width: 1600px) {
		font-size: 4rem;
	}
}

.bidType {
	font-size: 3rem;

	@media (max-width: 1600px) {
		font-size: 2rem;
	}
}

.placeholder {
	font-size: 6vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
}

.sblogo {
	width: 100%;
	display: flex;
	justify-content: center;
	font-size: 3vh;
	font-weight: 400;
}

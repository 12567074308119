.paddleNum {
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.change {
	border: none;
	padding: 0.4rem;
	border-radius: 8px;
	white-space: nowrap;
	background: var(--admin-accent-2);
	color: white;
}

.success {
	background: var(--admin-accent-1);
}

.confirm,
.loading {
	border: none;
	padding: 0.4rem 0.6rem;
	border-radius: 8px;
	white-space: nowrap;
	background: var(--admin-accent-1);
	color: white;
}

.cancel {
	border: none;
	padding: 0.4rem 0.6rem;
	border-radius: 8px;
	white-space: nowrap;
	background: var(--warning-color);
	color: white;
}

.error {
	color: var(--warning-color);
}

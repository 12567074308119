@import 'styles/variables';

.adminpages__adduser {
	height: 100%;
	width: 100%;
	margin-top: 4rem;
	padding: 2rem;
	// background-color: $background-color;

	&back {
		height: 2rem;
		width: 6rem;
		border-radius: 12px;
		background-color: white;
		// border: solid 1px $accent-color-1;
		margin-bottom: 1rem;
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	&__row {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem;
		position: relative;
		background-color: white;
	}

	.row-1 {
		// border-top: solid 1px $accent-color-1;
		// border-right: solid 1px $accent-color-1;
		// border-bottom: solid 1px $secondary-color-dark;
		// border-left: solid 1px $accent-color-1;
	}

	.row-2 {
		// border-right: solid 1px $accent-color-1;
		// border-bottom: solid 1px $accent-color-1;
		// border-left: solid 1px $accent-color-1;
		// flex-direction: row;
		height: 100%;
	}

	&__info {
		position: fixed;
		top: 45%;
		height: 10%;
		width: 50%;
		z-index: 1;

		&__message {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__header {
		margin-bottom: 2rem;
	}

	&__back {
		margin-left: 2rem;
		padding: 0.5rem 1rem;
		border-radius: 12px;
	}

	&__formcontainer {
		height: 100%;
		width: 60%;
	}

	&__usertype,
	&__action {
		height: 2.5rem;
		width: 7rem;
		font-size: 1.1rem;
		border-radius: 12px;
		background-color: white;
		margin-bottom: 1rem;
		margin-right: 1rem;

		&.active {
			background: var(--admin-accent-1);
			color: white;
		}
	}

	.adminpages__adduser__row.row-1 {
		.dragdropinput__input {
			width: 10vw;
			height: 10vw;
			border-radius: 100%;
			padding: 2rem;
			text-align: center;

			.dragdropinput__input__instruction {
				display: flex;
				flex-direction: column;
				align-items: center;

				svg {
					margin-bottom: 1rem;
				}
			}
		}
	}
}

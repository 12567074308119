.header {
	height: 8%;
	width: 100%;
	background: var(--primary-color-dark);
	color: var(--primary-color-light);
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 5%;
}

.lotNumber {
	text-transform: uppercase;
	margin-right: 1rem;
	white-space: nowrap;
}

.title {
	max-width: 90%;
	overflow: hidden;
}

.viewerpanel {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2%;
    border-radius: 0 0 12px 12px;
    opacity: 0.9;

    &__title {
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 700;
    }

    &__details {
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
    }

    &__heading {
        font-weight: 700;
        margin-right: 0.5rem;
    }

    &__value {
        font-weight: 400;
    }
}
.mobilelotdetail {
	height: 75%;
	width: 100%;
	position: absolute;
	top: 16%;
	z-index: 1;
	overflow-y: auto;
	background: var(--primary-color-light);

	&.full {
		height: 85%;
	}

	&__back {
		position: absolute;
		border-radius: 100%;
		height: 3rem;
		width: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 2%;
		left: 2%;
		z-index: 1000;
	}

	.biddermobile__mainimage {
		height: 35vh;
	}

	&__info {
		width: 100%;
		padding: 0.5rem;
	}

	&__estimate,
	&__title,
	&__description {
		margin: 1rem 0;
	}

	&__estimate {
		text-transform: uppercase;
	}
}

.container {
	height: 8vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.5rem 1rem;
	transition: height 0.5s;
	position: relative;
	text-transform: capitalize;
}

.bidOther {
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	border-radius: 12px 12px 12px 0;
	border: solid 1px var(--secondary-color-dark);
	background: var(--primary-color-light);
	opacity: 0.9;
	position: absolute;
	left: 5%;
}

.bidIsYou {
	border-radius: 12px 12px 0 12px;
	background: var(--accent-offset-2);
	height: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	border: solid 1px var(--secondary-color-dark);
	opacity: 0.9;
	position: absolute;
	right: 5%;
}

.name {
	margin-left: 1rem;
}

.isVoid {
	text-decoration: line-through;
}

.visibilityHidden {
	visibility: hidden;
}

.displayNone {
	display: none;
}

.animateOther {
	animation: slide-in-left 0.5s;
}

.animateIsYou {
	animation: slide-in-right 0.5s;
}

@keyframes slide-in-left {
	0% {
		left: -100%;
	}
	50% {
		left: 5%;
	}
	100% {
		left: 5%;
	}
}

@keyframes slide-in-right {
	0% {
		right: -100%;
	}
	50% {
		right: 5%;
	}
	100% {
		right: 5%;
	}
}

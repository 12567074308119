.catalogue {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2% 2% 2% 10%;

	.catalogue__row {
		height: 70%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.displaynone {
		display: none;
	}

	.hidden {
		visibility: hidden;
	}
}

@import 'styles/variables';

.clerkconsole .currentlot {
	padding: 1%;
	width: 48%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__header {
		font-weight: 700;
		height: 10%;
		width: 95%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-transform: capitalize;
	}

	&__title {
		height: 8%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: 400;
	}

	&__biddingstatus {
		height: 8%;
		font-weight: 700;
	}

	&__image {
		height: 60%;
		width: 100%;
		background: var(--secondary-color-light);

		&.withdrawn {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			display: block;
			margin-inline: auto;
			max-width: 100%;
			max-height: 100%;
			// border-radius: 20px;
		}
	}

	&__auctionprogress {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 4%;

		&__progressbar {
			height: 100%;
			width: 65%;
			border-radius: 9px;
			background-color: white;
			overflow: hidden;

			&_inner {
				height: 100%;
				border-radius: 9px;
			}
		}

		&__lotcount {
			white-space: nowrap;
			overflow: hidden;
			width: 30%;
			text-overflow: ellipsis;
			font-weight: 700;
			letter-spacing: 0.008vw;
		}
	}
}


.dragdropinput {
    height: 100%;
    width: 100%;

    &__input {
        height: 100%;
        width: 100%;
        // margin-top: 24px;
        border: 1.5px #3D6EFF dashed;
        background-color: #F0F4FF;
        border-radius: 12px;
        color: #3D6EFF;
        display: flex;
        align-items: center;
        justify-content: center;

        &.drag-over {
            background-color: #3D6EFF;
            border: 1.5px #F0F4FF dashed;
            color: #F0F4FF;
        }

        &__instruction {
            font-weight: 400;
            color: black;
        }

        &__dummy {
            font-weight: 600;
            cursor: pointer;
            color: #3D6EFF;
        }
    }

    &__file {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &__icon {
            font-size: 20px;
            margin-right: 20px;
        }

        &__action {
            margin-left: 20px;
            background-color: white;
            border: none;

            &__icon {
                font-size: 20px;

                &.delete {
                    color: red;
                }

                &.confirm {
                    color: green;
                }
            }
        }
    }



}
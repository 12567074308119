@import "styles/variables";

.addauction__details__increments {
    width: 100%;

    &__controls {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }


    .controls__icon {
        margin-left: 1rem;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;

        th, tr, td {
            padding: 1rem;
            text-align: center;
            border-bottom: solid 1px #EDF0F7;

            input {
                width: 100%;
                text-align: center;
                border: none;
            }
        }
    }

}
@import 'styles/variables';

.auctionresults {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: auto;
}

.auctionresults__modal__wrapper {
	position: fixed;
	height: 90%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auctionresults__shadow {
	position: absolute;
	height: 120%;
	width: 102%;
	background-color: black;
	opacity: 0.4;
}

.auctionresults__modal {
	max-height: 70%;
	max-width: 70%;
	padding: 5rem;
	background-color: white;
	opacity: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow-y: auto;
	border-radius: 12px;
}

.auctionresults__modal__title {
	font-size: 1.2rem;
	margin-bottom: 2rem;
	text-transform: capitalize;
}

.auctionresults__row {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
	padding: 0 4rem;
}

.auctionresults__header {
	margin-top: 2rem;
	font-size: 1.8rem;
}

.row-2 {
	display: flex;
	justify-content: space-between;
}

.auctionresults__export {
	// background-color: $accent-color-1;
	// color: white;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 8px;
}

.auctionresults__back {
	// background-color: $admin-accent-1;
	// color: white;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 8px;
}

.row-3 {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.auctionresults__summary__item {
	margin-bottom: 0.2rem;
}

.auctionresults__table__wrapper {
	width: 100%;
	overflow: auto;
	border-radius: 12px;
	border: 1px solid #d7dceb;
}

.auctionresults__table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	border-style: hidden;
	font-weight: 400;

	thead {
		background-color: #d7dceb;

		tr {
			height: 3rem;
			text-align: left;
		}
	}

	tbody {
		background-color: #edf0f7;
	}

	tr {
		height: 4rem;

		&.winner {
			background: var(--admin-accent-1);
			color: var(--primary-color-light);
		}
	}

	th,
	td {
		padding: 0.5rem 1rem;
	}
}

.auctionresults__showall {
	padding: 0.5rem 0.8rem;
	background-color: white;
	border: none;
	border-radius: 8px;

	&:hover {
		background-color: #3ab99c;
		color: white;
	}
}
